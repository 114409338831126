import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import CalendarInputForm from '../../../components/inputs-form/calendar-input-form';
import InputForm from '../../../components/inputs-form/input-form';
import LabelForm from '../../../components/inputs-form/label-form';
import SelectInputForm from '../../../components/inputs-form/select-input-form';
import Typography from '../../../components/typography';
import UploadPhoto from '../../../components/upload-file/upload-file';
import useFileUploadS3, {
  DocumentToUpload,
  HandleImageUploadResponse,
  UploadMultipleImagesParams,
} from '../../../hooks/use-file-upload-s3';
import { useTranslation } from '../../../hooks/use-translation';
import { OnboardingApplication } from '../../../types/applicant.types';
import { DocumentType, ExistingFile } from '../../../types/document.types';
import { DriverSkillInput } from '../../../types/driver.types';
import { VehicleDetailsInput } from '../../../types/vehicle.types';
import states from '../../../util/states-titlecase.json';
import { OutletContextProps } from '../application-steps.page';
import ButtonSteps from '../components/buttons-steps';
import {
  PostVehicleDetailsParams,
  postVehicleDetails,
} from './vehicle-details.service';

const defaultValues: VehicleDetailsInput = {
  vehicleId: undefined,
  insuranceId: undefined,
  make: '',
  model: '',
  vin: '',
  license_plate_number: '',
  license_plate_state: '',
  license_plate_expiration: undefined,
  insurance_type: undefined,
  insurance_expiration: undefined,
};

function VehicleDetailsForm(): JSX.Element {
  const { onNextStep, setOnboardingApplication, onboardingApplication } =
    useOutletContext<OutletContextProps>();
  const { t } = useTranslation('vehicle-details');
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const applicantId = onboardingApplication?.applicant?.id || '';

  const vehicleDetailsMutation = useMutation({
    mutationFn: (params: PostVehicleDetailsParams) => {
      return postVehicleDetails(params);
    },
    onSuccess: (response: OnboardingApplication) => {
      setSubmitLoading(false);
      setOnboardingApplication(response);
      onNextStep();
    },
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const { handleMultipleImageUpload } = useFileUploadS3();

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, isSubmitted },
  } = useForm<VehicleDetailsInput>({
    defaultValues,
  });

  // Documents
  // Existing files refers to files already stored on S3 and the DB

  // Vehicle photos
  const [vehiclePhotos, setVehiclePhotos] = useState<FileList | null>(null);
  const [vehiclePhotosExistingFiles, setVehiclePhotosExistingFiles] = useState<
    ExistingFile[]
  >([]);

  // Driver Skills and Certifications
  const [driverSkills, setDriverSkills] = useState<FileList | null>(null);
  const [driverSkillsExistingFiles, setDriverSkillsExistingFiles] = useState<
    ExistingFile[]
  >([]);

  const hasPreSelectedSkillsOrCertifications =
    (onboardingApplication?.applicant.driver_skills_selected_options || [])
      .length > 0;

  // Proof of insurance
  const [proofOfInsurance, setProofOfInsurance] = useState<FileList | null>(
    null,
  );
  const [proofOfInsuranceExistingFiles, setProofOfInsuranceExistingFiles] =
    useState<ExistingFile[]>([]);

  // Initilazing data
  useEffect(() => {
    if (onboardingApplication) {
      const currentVehicle = onboardingApplication.applicant.vehicles?.[0];
      setValue('vehicleId', currentVehicle?.id || undefined);
      setValue('insuranceId', currentVehicle?.insurance.id || undefined);
      setValue('make', currentVehicle?.make || '');
      setValue('model', currentVehicle?.model || '');
      setValue('vin', currentVehicle?.vin || '');
      setValue(
        'license_plate_number',
        currentVehicle?.license_plate_number || '',
      );
      setValue(
        'license_plate_state',
        currentVehicle?.license_plate_state || '',
      );
      setValue(
        'license_plate_expiration',
        currentVehicle?.license_plate_expiration
          ? new Date(currentVehicle?.license_plate_expiration)
          : undefined,
      );
      setValue(
        'insurance_type',
        currentVehicle?.insurance?.insurance_type || undefined,
      );
      setValue(
        'insurance_expiration',
        currentVehicle?.insurance?.expiration
          ? new Date(currentVehicle?.insurance?.expiration)
          : undefined,
      );

      setVehiclePhotosExistingFiles(currentVehicle?.images || []);
      setProofOfInsuranceExistingFiles(
        currentVehicle?.insurance?.documents || [],
      );
      setDriverSkillsExistingFiles(
        onboardingApplication.applicant.driver?.skills || [],
      );
    }
  }, [onboardingApplication, setValue]);

  const onSubmit: SubmitHandler<VehicleDetailsInput> = async formData => {
    if (!applicantId) return;

    if (
      ((vehiclePhotos || []).length === 0 &&
        vehiclePhotosExistingFiles.length === 0) ||
      ((proofOfInsurance || []).length === 0 &&
        proofOfInsuranceExistingFiles.length === 0)
    )
      return;

    if (
      hasPreSelectedSkillsOrCertifications &&
      (driverSkills || []).length === 0 &&
      // eslint-disable-next-line no-underscore-dangle
      driverSkillsExistingFiles.filter(file => file.signed_id && !file._destroy)
        .length === 0
    )
      return;

    setSubmitLoading(true);

    const vehiclePhotoFiles: DocumentToUpload[] = Array.from(
      vehiclePhotos || [],
    ).map(file => ({
      documentType: DocumentType.VEHICLE_PHOTOS,
      file,
    }));

    const driverSkillsFiles: DocumentToUpload[] = Array.from(
      driverSkills || [],
    ).map(file => ({
      documentType: DocumentType.DRIVER_SKILLS_AND_CERTIFICATIONS,
      file,
    }));

    const proofOfInsuranceFiles: DocumentToUpload[] = Array.from(
      proofOfInsurance || [],
    ).map(file => ({
      documentType: DocumentType.PROOF_OF_INSURANCE,
      file,
    }));

    const multipleDocuments: UploadMultipleImagesParams = {
      applicantId,
      documents: [
        ...vehiclePhotoFiles,
        ...driverSkillsFiles,
        ...proofOfInsuranceFiles,
      ],
    };

    handleMultipleImageUpload(multipleDocuments)
      .then((responses: HandleImageUploadResponse[]) => {
        const newSignedIdsFilesVehicle: (string | null)[] = responses
          .filter(
            signedIdsByType =>
              signedIdsByType.documentType === DocumentType.VEHICLE_PHOTOS,
          )
          .map(res => res.signedId);

        const existingVehiclePhotosSignedIds = vehiclePhotosExistingFiles.map(
          file => file.signed_id,
        );

        const newSignedIdsFilesInsurance: (string | null)[] = responses
          .filter(
            signedIdsByType =>
              signedIdsByType.documentType === DocumentType.PROOF_OF_INSURANCE,
          )
          .map(res => res.signedId);

        const existingSignedIdsFilesInsurance =
          proofOfInsuranceExistingFiles.map(file => file.signed_id);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const driverSkillsSignedIds = responses
          .filter(
            signedIdsByType =>
              signedIdsByType.documentType ===
              DocumentType.DRIVER_SKILLS_AND_CERTIFICATIONS,
          )
          .map(res => res.signedId);

        const driverSkillsExistingFilesUpdated: DriverSkillInput[] = [];
        driverSkillsExistingFiles.forEach(skill => {
          const driverSkillInputTmp: DriverSkillInput = {
            id: skill.id,
            document: skill.signed_id || '',
            // eslint-disable-next-line no-underscore-dangle
            _destroy: skill._destroy,
          };
          driverSkillsExistingFilesUpdated.push(driverSkillInputTmp);
        });

        driverSkillsSignedIds.forEach(signedId => {
          driverSkillsExistingFilesUpdated.push({
            document: signedId,
          });
        });

        return Promise.resolve({
          vehicleDetails: formData,
          signedIdsFilesVehicle: newSignedIdsFilesVehicle.concat(
            existingVehiclePhotosSignedIds,
          ),
          signedIdsFilesInsurance: newSignedIdsFilesInsurance.concat(
            existingSignedIdsFilesInsurance,
          ),
          driverSkills: driverSkillsExistingFilesUpdated,
          applicantId,
        });
      })
      .then((response: PostVehicleDetailsParams) => {
        vehicleDetailsMutation.mutate(response);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error while submitting files - vehicle details', error);
        setSubmitLoading(false);
        enqueueSnackbar('Error while submitting files');
      });
  };

  const driverSkillsFilesRequiered =
    hasPreSelectedSkillsOrCertifications &&
    isSubmitted &&
    (driverSkills || []).length === 0 &&
    (driverSkillsExistingFiles || []).filter(
      // eslint-disable-next-line no-underscore-dangle
      file => file.signed_id && !file._destroy,
    ).length === 0;

  const photoVehicleRequiered =
    isSubmitted &&
    (vehiclePhotos || []).length === 0 &&
    (vehiclePhotosExistingFiles || []).length === 0;

  const proofOfInsuranceRequired =
    isSubmitted &&
    (proofOfInsurance || []).length === 0 &&
    (proofOfInsuranceExistingFiles || []).length === 0;

  return (
    <div>
      <form
        className="flex flex-col space-y-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* // Make, model, vin, license plate number, license plate state, license plate exp */}
        <div className="flex flex-col space-y-4">
          <InputForm
            label={t('MAKE_LABEL')}
            error={errors.make}
            inputProps={{
              ...register('make', {
                required: {
                  value: true,
                  message: t('REQUIRED_FIELD', {
                    ns: 'common',
                    replace: { fieldName: t('MAKE_LABEL') },
                  }),
                },
              }),
            }}
            required
          />
          <InputForm
            label={t('MODEL_LABEL')}
            error={errors.model}
            inputProps={{
              ...register('model', {
                required: {
                  value: true,
                  message: t('REQUIRED_FIELD', {
                    ns: 'common',
                    replace: { fieldName: t('MODEL_LABEL') },
                  }),
                },
              }),
            }}
            required
          />
          <InputForm
            label={t('VIN_LABEL')}
            error={errors.vin}
            inputProps={{
              ...register('vin', {
                required: {
                  value: true,
                  message: t('REQUIRED_FIELD', {
                    ns: 'common',
                    replace: { fieldName: t('VIN_LABEL') },
                  }),
                },
              }),
            }}
            required
          />
          <InputForm
            label={t('LICENSE_PLATE_NUMBER_LABEL')}
            error={errors.license_plate_number}
            inputProps={{
              ...register('license_plate_number', {
                required: {
                  value: true,
                  message: t('REQUIRED_FIELD', {
                    ns: 'common',
                    replace: { fieldName: t('LICENSE_PLATE_NUMBER_LABEL') },
                  }),
                },
              }),
            }}
            required
          />
          <SelectInputForm
            label={t('LICENSE_PLATE_STATE_LABEL')}
            error={errors.license_plate_state}
            control={control}
            controllerProps={{
              name: 'license_plate_state',
              rules: {
                required: {
                  value: true,
                  message: t('REQUIRED_FIELD', {
                    ns: 'common',
                    replace: { fieldName: t('LICENSE_PLATE_STATE_LABEL') },
                  }),
                },
              },
            }}
            items={states.map(state => {
              return { value: state.abbreviation, text: state.name };
            })}
            required
          />

          <div>
            <CalendarInputForm
              label={t('LICENSE_PLATE_EXPIRATION_LABEL')}
              control={control}
              error={errors.license_plate_expiration}
              setValue={(newDate: Date | undefined) => {
                setValue('license_plate_expiration', newDate);
                clearErrors('license_plate_expiration');
              }}
              controllerProps={{
                name: 'license_plate_expiration',
                rules: {
                  required: {
                    value: true,
                    message: t('REQUIRED_FIELD', {
                      ns: 'common',
                      replace: {
                        fieldName: t('LICENSE_PLATE_EXPIRATION_LABEL'),
                      },
                    }),
                  },
                },
              }}
              required
            />
          </div>
        </div>

        {/* Photo documentation */}
        {/* Vehicle Photo */}
        <div>
          <Typography size="xl" className="mb-4 text-xl" color="primary">
            {t('PHOTOS_DOCUMENTATION_TITLE')}
          </Typography>

          <div>
            <div className="mb-4">
              <LabelForm
                label={t('PHOTO_VEHICLE_PHOTO_LABEL')}
                labelHelp={t('PHOTO_VEHICLE_PHOTO_DESC')}
                required
                hasError={photoVehicleRequiered}
                labelClassName="font-normal text-base text-primary"
              />
            </div>
            <UploadPhoto
              inputId="input-vehicle-photos"
              existingFiles={vehiclePhotosExistingFiles}
              onRemoveExistingFile={(signedIdToRemove: string) => {
                setVehiclePhotosExistingFiles(
                  [...(vehiclePhotosExistingFiles || [])].filter(
                    file => file.signed_id !== signedIdToRemove,
                  ),
                );
              }}
              files={vehiclePhotos}
              setFiles={setVehiclePhotos}
              hasError={photoVehicleRequiered}
            />
          </div>
        </div>

        {/* Driver Skills & Certifications */}
        <div>
          <div>
            <div className="mb-4">
              <LabelForm
                label={t('DRIVER_SKILLS_DRIVER_CERTIFICATION_LABEL')}
                labelHelp={t('DRIVER_SKILLS_DRIVER_CERTIFICATION_DESC')}
                required={hasPreSelectedSkillsOrCertifications}
                hasError={driverSkillsFilesRequiered}
                labelClassName="font-normal text-base text-primary"
              />
            </div>
            <UploadPhoto
              inputId="input-driver-skills-and-cert"
              existingFiles={driverSkillsExistingFiles}
              onRemoveExistingFile={(signedIdToRemove: string) => {
                setDriverSkillsExistingFiles(
                  [...(driverSkillsExistingFiles || [])].map(file => {
                    if (file.signed_id === signedIdToRemove) {
                      return {
                        ...file,
                        _destroy: true,
                      };
                    }
                    return file;
                  }),
                );
              }}
              files={driverSkills}
              setFiles={setDriverSkills}
              hasError={driverSkillsFilesRequiered}
            />
          </div>
        </div>

        {/* Proof of insurance */}
        <div>
          <div>
            <div className="mb-4">
              <LabelForm
                label={t('PROOF_OF_INSURANCE_LABEL')}
                labelHelp={t('PROOF_OF_INSURANCE_DESC')}
                required
                hasError={proofOfInsuranceRequired}
                labelClassName="font-normal text-base text-primary"
              />
            </div>
            <UploadPhoto
              inputId="input-proof-of-insurance"
              existingFiles={proofOfInsuranceExistingFiles}
              onRemoveExistingFile={(signedIdToRemove: string) => {
                setProofOfInsuranceExistingFiles(
                  [...(proofOfInsuranceExistingFiles || [])].filter(
                    file => file.signed_id !== signedIdToRemove,
                  ),
                );
              }}
              files={proofOfInsurance}
              setFiles={setProofOfInsurance}
              hasError={proofOfInsuranceRequired}
            />
          </div>
        </div>

        {/* Insurance information */}
        <div className="flex flex-col space-y-4">
          <SelectInputForm
            label={t('INSURANCE_TYPE_LABEL')}
            error={errors.insurance_type}
            control={control}
            controllerProps={{
              name: 'insurance_type',
              rules: {
                required: {
                  value: true,
                  message: t('REQUIRED_FIELD', {
                    ns: 'common',
                    replace: { fieldName: t('INSURANCE_TYPE_LABEL') },
                  }),
                },
              },
            }}
            items={[
              { value: 'commercial', text: 'Commercial' },
              { value: 'personal', text: 'Personal' },
            ]}
            required
          />

          <div>
            <CalendarInputForm
              label={t('INSURANCE_EXPIRATION_LABEL')}
              control={control}
              error={errors.insurance_expiration}
              setValue={(newDate: Date | undefined) => {
                setValue('insurance_expiration', newDate);
                clearErrors('insurance_expiration');
              }}
              required
              controllerProps={{
                name: 'insurance_expiration',
                rules: {
                  required: {
                    value: true,
                    message: t('REQUIRED_FIELD', {
                      ns: 'common',
                      replace: {
                        fieldName: t('INSURANCE_EXPIRATION_LABEL'),
                      },
                    }),
                  },
                },
              }}
            />
          </div>
        </div>

        <ButtonSteps
          submitLoading={submitLoading}
          hasErrors={
            Object.keys(errors).length > 0 ||
            photoVehicleRequiered ||
            driverSkillsFilesRequiered ||
            proofOfInsuranceRequired
          }
        />
      </form>
    </div>
  );
}

export default VehicleDetailsForm;
