import { PostHogClientProvider } from '@dispatch/posthog-openfeature-provider-js';
import { OpenFeature, OpenFeatureProvider } from '@openfeature/react-sdk';
import { ReactNode } from 'react';

const posthogConfig = {
  token: window.POSTHOG_API_KEY,
};

const provider = new PostHogClientProvider(posthogConfig);

OpenFeature.setProvider(provider);

function FeatureFlagProvider({ children }: { children: ReactNode }): ReactNode {
  return <OpenFeatureProvider>{children}</OpenFeatureProvider>;
}

export default FeatureFlagProvider;
