import MainRoutes from './main.routes';
import { CurrentUserProvider } from './providers/current-user.context';
import FeatureFlagProvider from './providers/feature-flag-provider';
import LanguageProvider from './providers/language.provider';
import ReactQueryClient from './providers/query-client.provider';
import Snackbar from './providers/snackbar.provider';
import IntercomContactWrapper from './services/intercom/intercom-contact';
// This is needed to apply the styles for the date picker (month and year dropdowns)
// Otherwise, the styles for those dropdowns are not applied
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-day-picker/dist/style.css';
import SentryErrorBoundary from './services/sentry/error-boundary';

function MainApp() {
  return (
    <FeatureFlagProvider>
      <SentryErrorBoundary>
        <ReactQueryClient>
          <LanguageProvider>
            <Snackbar>
              <CurrentUserProvider>
                <IntercomContactWrapper>
                  <MainRoutes />
                </IntercomContactWrapper>
              </CurrentUserProvider>
            </Snackbar>
          </LanguageProvider>
        </ReactQueryClient>
      </SentryErrorBoundary>
    </FeatureFlagProvider>
  );
}

export default MainApp;
