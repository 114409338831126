import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { TaxFilingStatusEnum } from '../../../__generated__/graphql';
import CalendarInputForm from '../../../components/inputs-form/calendar-input-form';
import InputForm from '../../../components/inputs-form/input-form';
import LabelForm from '../../../components/inputs-form/label-form';
import RadioGroupInputForm from '../../../components/inputs-form/radio-group-input-form';
import SelectInputForm from '../../../components/inputs-form/select-input-form';
import Typography from '../../../components/typography';
import UploadPhoto from '../../../components/upload-file/upload-file';
import useFileUploadS3, {
  DocumentToUpload,
  HandleImageUploadResponse,
  UploadMultipleImagesParams,
} from '../../../hooks/use-file-upload-s3';
import { useTranslation } from '../../../hooks/use-translation';
import { OnboardingApplication } from '../../../types/applicant.types';
import { DocumentType, ExistingFile } from '../../../types/document.types';
import { DriverDetailsInput } from '../../../types/driver-details.input';
import states from '../../../util/states-titlecase.json';
import { OutletContextProps } from '../application-steps.page';
import ButtonSteps from '../components/buttons-steps';
import postDriverDetails, {
  PostDriverDetailsParams,
} from './driver-details.service';

function DriverDetailsForm(): JSX.Element {
  const {
    onNextStep,
    setOnboardingApplication,
    onboardingApplication,
    checkForRejections,
  } = useOutletContext<OutletContextProps>();
  const { t } = useTranslation('driver-details');
  const applicantId = onboardingApplication?.applicant.id || '';

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const driverDetailsMutation = useMutation({
    mutationFn: (params: PostDriverDetailsParams) => {
      return postDriverDetails(params);
    },
    onSuccess: (response: OnboardingApplication) => {
      setSubmitLoading(false);
      setOnboardingApplication(response);
      checkForRejections({
        application: response,
        onNoRejections: () => {
          onNextStep();
        },
      });
    },
  });

  const { handleMultipleImageUpload } = useFileUploadS3();

  // Form
  const {
    control,
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, isSubmitted },
  } = useForm<DriverDetailsInput>({
    defaultValues: {
      street: '',
      aptOrSuite: '',
      city: '',
      state: '',
      zipCode: '',
      taxFilingStatus: undefined,
      driverLicenseExpiration: undefined,
    },
  });

  // Documents
  // Existing files refers to files already stored on S3 and the DB

  // Vehicle photos
  const [profilePhoto, setProfilePhoto] = useState<FileList | null>(null);
  const [profilePhotoExistingFile, setProfilePhotoExistingFile] = useState<
    ExistingFile[]
  >([]);

  // Driver license front
  const [driverLicenseFront, setDriverLicenseFront] = useState<FileList | null>(
    null,
  );
  const [driverLicenseFrontExistingFile, setDriverLicenseFrontExistingFile] =
    useState<ExistingFile[]>([]);

  // Driver license back
  const [driverLicenseBack, setDriverLicenseBack] = useState<FileList | null>(
    null,
  );
  const [driverLicenseBackExistingFile, setDriverLicenseBackExistingFile] =
    useState<ExistingFile[]>([]);

  useEffect(() => {
    if (onboardingApplication) {
      setValue('addressId', onboardingApplication.applicant.address?.id || '');
      setValue(
        'street',
        onboardingApplication.applicant.address?.street_name || '',
      );
      setValue(
        'aptOrSuite',
        onboardingApplication.applicant.address?.building_number || '',
      );
      setValue('city', onboardingApplication.applicant.address?.city || '');
      setValue('state', onboardingApplication.applicant.address?.state || '');
      setValue(
        'zipCode',
        onboardingApplication.applicant.address?.zip_code || '',
      );
      setValue(
        'taxFilingStatus',
        onboardingApplication.applicant.driver
          ?.tax_filling_status as TaxFilingStatusEnum,
      );
      setValue(
        'driverLicenseExpiration',
        onboardingApplication.applicant.driver?.license?.expiration
          ? new Date(onboardingApplication.applicant.driver.license.expiration)
          : undefined,
      );
      if (
        onboardingApplication.applicant.profile_photo &&
        onboardingApplication.applicant.profile_photo.signed_id
      ) {
        setProfilePhotoExistingFile([
          onboardingApplication.applicant.profile_photo,
        ]);
      }
      if (
        onboardingApplication.applicant.driver?.license
          ?.driver_license_front_image &&
        onboardingApplication.applicant.driver.license
          .driver_license_front_image.signed_id
      ) {
        setDriverLicenseFrontExistingFile([
          onboardingApplication.applicant.driver.license
            .driver_license_front_image,
        ]);
      }
      if (
        onboardingApplication.applicant.driver?.license
          ?.driver_license_back_image &&
        onboardingApplication.applicant.driver.license.driver_license_back_image
          .signed_id
      ) {
        setDriverLicenseBackExistingFile([
          onboardingApplication.applicant.driver.license
            .driver_license_back_image,
        ]);
      }
    }
  }, [onboardingApplication, setValue]);

  const onSubmit: SubmitHandler<DriverDetailsInput> = async formData => {
    if (!applicantId) return;

    if (
      ((profilePhoto || []).length === 0 &&
        profilePhotoExistingFile.length === 0) ||
      ((driverLicenseFront || []).length === 0 &&
        driverLicenseFrontExistingFile.length === 0) ||
      ((driverLicenseBack || []).length === 0 &&
        driverLicenseBackExistingFile.length === 0)
    )
      return;

    setSubmitLoading(true);

    const profilePhotoFiles: DocumentToUpload[] = Array.from(
      profilePhoto || [],
    ).map(file => ({
      documentType: DocumentType.PROFILE_PHOTO,
      file,
    }));

    const driverLicenseFrontFiles: DocumentToUpload[] = Array.from(
      driverLicenseFront || [],
    ).map(file => ({
      documentType: DocumentType.DRIVER_LICENSE_FRONT,
      file,
    }));

    const driverLicenseBackFiles: DocumentToUpload[] = Array.from(
      driverLicenseBack || [],
    ).map(file => ({
      documentType: DocumentType.DRIVER_LICENSE_BACK,
      file,
    }));

    const multipleDocuments: UploadMultipleImagesParams = {
      applicantId,
      documents: [
        ...profilePhotoFiles,
        ...driverLicenseFrontFiles,
        ...driverLicenseBackFiles,
      ],
    };

    handleMultipleImageUpload(multipleDocuments)
      .then((responses: HandleImageUploadResponse[]) => {
        const newSignedIdsFilesProfilePhoto = responses
          .filter(
            signedIdsByType =>
              signedIdsByType.documentType === DocumentType.PROFILE_PHOTO,
          )
          .map(res => res.signedId);

        const existingProfilePhotosSignedIds = profilePhotoExistingFile.map(
          file => file.signed_id,
        );

        const newSignedIdsFilesLicenseFront = responses
          .filter(
            signedIdsByType =>
              signedIdsByType.documentType ===
              DocumentType.DRIVER_LICENSE_FRONT,
          )
          .map(res => res.signedId);

        const existingSignedIdsFilesLicenseFront =
          driverLicenseBackExistingFile.map(file => file.signed_id);

        const newSignedIdsFilesLicenseBack = responses
          .filter(
            signedIdsByType =>
              signedIdsByType.documentType === DocumentType.DRIVER_LICENSE_BACK,
          )
          .map(res => res.signedId);

        const existingSignedIdsFilesLicenseBack =
          driverLicenseBackExistingFile.map(file => file.signed_id);

        return Promise.resolve({
          driverDetails: formData,
          signedIdFileProfilePhoto:
            newSignedIdsFilesProfilePhoto[0] ||
            existingProfilePhotosSignedIds[0],
          signedIdFileLicenseFront:
            newSignedIdsFilesLicenseFront[0] ||
            existingSignedIdsFilesLicenseFront[0],
          signedIdFileLicenseBack:
            newSignedIdsFilesLicenseBack[0] ||
            existingSignedIdsFilesLicenseBack[0],
          applicantId,
        });
      })
      .then((response: PostDriverDetailsParams) => {
        driverDetailsMutation.mutate(response);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error while submitting files - driver details', error);
        setSubmitLoading(false);
        enqueueSnackbar('Error while submitting files');
      });
  };

  const profilePhotoRequired =
    isSubmitted &&
    (profilePhoto || []).length === 0 &&
    (profilePhotoExistingFile || []).length === 0;

  const driverLicenseFrontRequired =
    isSubmitted &&
    (driverLicenseFront || []).length === 0 &&
    (driverLicenseFrontExistingFile || []).length === 0;

  const dirverLicenseBackRequiered =
    isSubmitted &&
    (driverLicenseBack || []).length === 0 &&
    (driverLicenseBackExistingFile || []).length === 0;

  return (
    <div>
      <form
        className="flex flex-col space-y-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col space-y-4">
          <InputForm
            label={t('ADDRESS_LABEL')}
            error={errors.street}
            inputProps={{
              ...register('street', {
                required: {
                  value: true,
                  message: t('REQUIRED_FIELD', {
                    ns: 'common',
                    replace: { fieldName: t('ADDRESS_LABEL') },
                  }),
                },
              }),
            }}
            required
          />
          <InputForm
            label={t('APT_OR_SUITE_LABEL')}
            error={errors.aptOrSuite}
            inputProps={{
              ...register('aptOrSuite'),
            }}
          />
          <InputForm
            label={t('CITY_LABEL')}
            error={errors.city}
            inputProps={{
              ...register('city', {
                required: {
                  value: true,
                  message: t('REQUIRED_FIELD', {
                    ns: 'common',
                    replace: { fieldName: t('CITY_LABEL') },
                  }),
                },
              }),
            }}
            required
          />
          <SelectInputForm
            label={t('STATE_LABEL')}
            error={errors.state}
            control={control}
            controllerProps={{
              name: 'state',
              rules: {
                required: {
                  value: true,
                  message: t('REQUIRED_FIELD', {
                    ns: 'common',
                    replace: { fieldName: t('STATE_LABEL') },
                  }),
                },
              },
            }}
            items={states.map(state => {
              return { value: state.abbreviation, text: state.name };
            })}
            required
          />
          <InputForm
            label={t('ZIP_CODE_LABEL')}
            error={errors.zipCode}
            inputProps={{
              ...register('zipCode', {
                required: {
                  value: true,
                  message: t('REQUIRED_FIELD', {
                    ns: 'common',
                    replace: { fieldName: t('ZIP_CODE_LABEL') },
                  }),
                },
              }),
            }}
            required
          />

          {/* TAX filing */}
          <RadioGroupInputForm
            label={t('TAX_FILING_STATUS_LABEL')}
            error={errors.taxFilingStatus}
            control={control}
            controllerProps={{
              name: 'taxFilingStatus',
              rules: {
                required: {
                  value: true,
                  message: t('REQUIRED_OPTION', { ns: 'common' }),
                },
              },
            }}
            items={[
              { value: 'ssn', text: 'SSN' },
              { value: 'ein', text: 'EIN' },
            ]}
            radioGroupProps={{
              onValueChange: e => e as TaxFilingStatusEnum,
              required: true,
            }}
          />
        </div>

        {/* Photo documentation */}
        {/* Profile Photo */}
        <div>
          <div>
            <div className="mb-4">
              <LabelForm
                label={t('DOCUMENT_PROFILE_PHOTO_TITLE')}
                labelHelp={t('DOCUMENT_PROFILE_PHOTO_DESC')}
                required
                hasError={profilePhotoRequired}
                labelClassName="font-normal text-base text-primary"
              />
            </div>
            <UploadPhoto
              inputId="input-profile-photos"
              existingFiles={profilePhotoExistingFile}
              onRemoveExistingFile={(signedIdToRemove: string) => {
                setProfilePhotoExistingFile(
                  [...(profilePhotoExistingFile || [])].filter(
                    file => file.signed_id !== signedIdToRemove,
                  ),
                );
              }}
              files={profilePhoto}
              setFiles={setProfilePhoto}
              multiple={false}
              hasError={profilePhotoRequired}
            />
          </div>
        </div>

        {/* Driver License */}
        <div>
          <Typography size="xl" className="mb-4 text-xl" color="primary">
            {t('DOCUMENT_DRIVER_LICENSE_TITLE')}
          </Typography>

          <div>
            <div className="mb-4">
              <LabelForm
                label={t('DOCUMENT_DRIVER_LICENSE_FRONT_TITLE')}
                labelHelp={t('DOCUMENT_DRIVER_LICENSE_FRONT_DESC')}
                required
                hasError={driverLicenseFrontRequired}
                labelClassName="font-normal text-base text-primary"
              />
            </div>
            <UploadPhoto
              inputId="input-license-front"
              existingFiles={driverLicenseFrontExistingFile}
              onRemoveExistingFile={(signedIdToRemove: string) => {
                setDriverLicenseFrontExistingFile(
                  [...(driverLicenseFrontExistingFile || [])].filter(
                    file => file.signed_id !== signedIdToRemove,
                  ),
                );
              }}
              files={driverLicenseFront}
              setFiles={setDriverLicenseFront}
              multiple={false}
              hasError={driverLicenseFrontRequired}
            />
          </div>
        </div>

        <div>
          <div>
            <div className="mb-4">
              <LabelForm
                label={t('DOCUMENT_DRIVER_LICENSE_BACK_TITLE')}
                labelHelp={t('DOCUMENT_DRIVER_LICENSE_BACK_DESC')}
                required
                hasError={dirverLicenseBackRequiered}
                labelClassName="font-normal text-base text-primary"
              />
            </div>
            <UploadPhoto
              inputId="input-license-back"
              existingFiles={driverLicenseBackExistingFile}
              onRemoveExistingFile={(signedIdToRemove: string) => {
                setDriverLicenseBackExistingFile(
                  [...(driverLicenseBackExistingFile || [])].filter(
                    file => file.signed_id !== signedIdToRemove,
                  ),
                );
              }}
              files={driverLicenseBack}
              setFiles={setDriverLicenseBack}
              multiple={false}
              hasError={dirverLicenseBackRequiered}
            />
          </div>
        </div>

        {/* License Expiration */}
        <div className="flex flex-col space-y-4">
          <CalendarInputForm
            label={t('DRIVER_LICENSE_EXPIRATION')}
            control={control}
            error={errors.driverLicenseExpiration}
            setValue={(newDate: Date | undefined) => {
              setValue('driverLicenseExpiration', newDate);
              clearErrors('driverLicenseExpiration');
            }}
            controllerProps={{
              name: 'driverLicenseExpiration',
              rules: {
                required: {
                  value: true,
                  message: t('REQUIRED_FIELD', {
                    ns: 'common',
                    replace: {
                      fieldName: t('DRIVER_LICENSE_EXPIRATION'),
                    },
                  }),
                },
              },
            }}
            required
          />
        </div>

        <ButtonSteps
          submitLoading={submitLoading}
          hasErrors={
            Object.keys(errors).length > 0 ||
            profilePhotoRequired ||
            driverLicenseFrontRequired ||
            dirverLicenseBackRequiered
          }
        />
      </form>
    </div>
  );
}

export default DriverDetailsForm;
