import { Input, InputProps } from '@dispatch-ui/react';
import { RefAttributes } from 'react';
import { FieldError } from 'react-hook-form';
import FormErrorLabel from '../form-error-label';
import LabelForm from './label-form';
import { getInputErrorClassName } from './styles.util';

function InputForm({
  error,
  inputProps,
  label,
  labelHelp,
  required = false,
  omitAterisk = false,
}: {
  error?: FieldError;
  inputProps: Omit<InputProps, 'data-testid'> & RefAttributes<HTMLInputElement>;
  label?: string;
  labelHelp?: string;
  required?: boolean;
  omitAterisk?: boolean;
}) {
  const inputClassName = getInputErrorClassName(!!error);

  return (
    <div>
      <div className="mb-1">
        <LabelForm
          label={label}
          labelHelp={labelHelp}
          required={required}
          hasError={!!error}
          omitAterisk={omitAterisk}
        />
      </div>
      <Input
        {...inputProps}
        className={inputClassName}
        data-testid={`${inputProps.name}-input`}
      />
      {error && <FormErrorLabel>{error.message}</FormErrorLabel>}
    </div>
  );
}

export default InputForm;
