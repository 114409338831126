/* eslint-disable jsx-a11y/anchor-is-valid */
import Cookies from 'js-cookie';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ButtonSpinner from '../../components/button-spinner';
import PassCodeInputForm from '../../components/inputs-form/passcode-input-form';
import Typography from '../../components/typography';
import { useTranslation } from '../../hooks/use-translation';
import { CurrentUserContext } from '../../providers/current-user.context';
import { authenticateWithMagicAuth } from './sign-in.service';

type PassCodeParams = {
  email: string;
};

function PassCode({ email }: PassCodeParams): JSX.Element {
  const { t } = useTranslation('sign-in');
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);
  const [code, setCode] = useState('');
  const [errorCode, setErrorCode] = useState<FieldError>();

  const { fetchCurrentUser } = useContext(CurrentUserContext);

  const [loading, setLoading] = useState(false);

  const verifyCode = useCallback(async () => {
    setSubmitted(true);
    if (code) {
      setLoading(true);
      authenticateWithMagicAuth({
        email,
        code,
      })
        .then(e => {
          if (e && e.error === 'email_not_supported_magic_auth') {
            navigate('/sign-in-admin');
            return;
          }

          fetchCurrentUser();
          if (Cookies.get('opening_id')) {
            window.location.href = `/api/v1/check-user-session?opening_id=${Cookies.get('opening_id')}`;
          } else {
            window.location.href = '/api/v1/check-user-session';
          }
          setLoading(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error('Error while validating code', e);
          enqueueSnackbar('Error while validating code', { variant: 'error' });
          setLoading(false);
        });
    }
  }, [code, email, fetchCurrentUser, navigate]);

  useEffect(() => {
    if (code.length === 6) {
      verifyCode();
    }
  }, [code, verifyCode]);

  useEffect(() => {
    if (!email || email.length < 6)
      setErrorCode({ message: t('EMAIL_REQUIRED'), type: 'required' });
    else setErrorCode(undefined);
  }, [email, submitted, t]);

  return (
    <div className="flex text-center justify-center">
      <div className="space-y-4 w-80">
        <Typography size="sm" color="gray-600">
          {t('CHECK_YOUR_EMAIL_DESC', { replace: { email } })}
        </Typography>
        <div className="text-left">
          <PassCodeInputForm
            disabled={loading}
            error={submitted ? errorCode : undefined}
            onInputChange={(value: string) => setCode(value)}
            inputProps={{
              name: 'code',
              value: code,
            }}
          />
        </div>

        <ButtonSpinner
          loading={loading}
          type="submit"
          data-testid="code-submit"
          fullWidth
          onClick={verifyCode}
        >
          {t('VERIFY_CODE')}
        </ButtonSpinner>
      </div>
    </div>
  );
}

export default PassCode;
