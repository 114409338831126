import {
  API_BASE_URL,
  API_RELATIVE_PATH,
} from '../../../constants/util.constants';
import { OnboardingApplication } from '../../../types/applicant.types';
import { DriverSkillInput } from '../../../types/driver.types';
import { VehicleDetailsInput } from '../../../types/vehicle.types';

export type PostVehicleDetailsParams = {
  vehicleDetails: VehicleDetailsInput;
  signedIdsFilesVehicle: (string | null)[];
  signedIdsFilesInsurance: (string | null)[];
  driverSkills: DriverSkillInput[];
  applicantId: string;
};

async function postVehicleDetails({
  vehicleDetails,
  signedIdsFilesVehicle,
  signedIdsFilesInsurance,
  driverSkills,
  applicantId,
}: PostVehicleDetailsParams): Promise<OnboardingApplication> {
  const url = `${API_BASE_URL}${API_RELATIVE_PATH}/intake/vehicle-details-driver-skills/${applicantId}`;

  const body = {
    vehicle_details_driver_skills: {
      vehicles_attributes: [
        {
          id: vehicleDetails.vehicleId,
          make: vehicleDetails.make,
          model: vehicleDetails.model,
          vin: vehicleDetails.vin,
          license_plate_number: vehicleDetails.license_plate_number,
          license_plate_state: vehicleDetails.license_plate_state,
          license_plate_expiration: vehicleDetails.license_plate_expiration,
          images: signedIdsFilesVehicle,
          insurance_attributes: {
            id: vehicleDetails.insuranceId,
            insurance_type: vehicleDetails.insurance_type,
            expiration: vehicleDetails.insurance_expiration,
            documents: signedIdsFilesInsurance,
          },
        },
      ],
      driver_skills_attributes: driverSkills.map(skill => {
        if (skill.document === '') {
          return { ...skill, document: undefined };
        }
        return skill;
      }),
    },
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  };

  return fetch(url, options).then(res => res.json());
}

export { postVehicleDetails };
