import {
  API_BASE_URL,
  API_RELATIVE_PATH,
} from '../../constants/util.constants';
import { Opening } from '../../types/opening.types';

type FetchOpeningsResult = {
  openings: Opening[];
  page_info: {
    has_next_page: boolean;
    has_previous_page: boolean;
  };
};

const fetchOpenings = async (
  textSearch: string,
  currentPage: number,
): Promise<FetchOpeningsResult> => {
  let url = `${API_BASE_URL}${API_RELATIVE_PATH}/openings?page=${currentPage}`;

  if (textSearch) {
    url = `${url}&q=${textSearch}`;
  }

  return fetch(url).then(res => res.json());
};

export default fetchOpenings;
