import { Button, Checkbox, Label } from '@dispatch-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { JSX } from 'react/jsx-runtime';
import FormErrorField from '../../../components/form-error-label';
import InputForm from '../../../components/inputs-form/input-form';
import PatternInputForm from '../../../components/inputs-form/pattern-input-form';
import RadioGroupInputForm from '../../../components/inputs-form/radio-group-input-form';
import {
  DISPATCH_HARASSMENT_POLICY_URL,
  DISPATCH_PLATFORM_PROVIDER_AGREEMENT_URL,
  DISPATCH_PRIVACY_POLICY_URL,
} from '../../../constants/util.constants';
import { useTranslation } from '../../../hooks/use-translation';
import { OnboardingApplication } from '../../../types/applicant.types';
import {
  AgeRange,
  PersonalInfoInput,
} from '../../../types/personal-info.types';
import { OutletContextProps } from '../application-steps.page';
import ButtonSteps from '../components/buttons-steps';
import postPersonalInfo from './personal-info.service';

function PersonalInfoForm(): JSX.Element {
  const { onNextStep, onboardingApplication, checkForRejections } =
    useOutletContext<OutletContextProps>();
  const { t } = useTranslation('personal-info');
  const navigate = useNavigate();
  const { openingId } = useParams();
  const { applicantId } = useParams();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const defaultValues: PersonalInfoInput = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    licensed_two_years_or_more: undefined,
    vehicle_currently_insured: undefined,
    age_range: undefined,
    terms_and_conditions_accepted: false,
    contact_by_phone_number_accepted: false,
  };

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<PersonalInfoInput>({
    defaultValues,
  });

  useEffect(() => {
    if (onboardingApplication) {
      setValue('email', onboardingApplication.applicant.email || '');
      setValue('first_name', onboardingApplication.applicant.first_name || '');
      setValue('last_name', onboardingApplication.applicant.last_name || '');
      setValue(
        'phone_number',
        onboardingApplication.applicant.phone_number || '',
      );

      // radio buttons
      setValue(
        'licensed_two_years_or_more',
        onboardingApplication.applicant.licensed_two_years_or_more,
      );
      setValue(
        'vehicle_currently_insured',
        onboardingApplication.applicant.vehicle_currently_insured,
      );
      setValue('age_range', onboardingApplication.applicant.age_range);

      // checkboxes
      setValue(
        'contact_by_phone_number_accepted',
        !!onboardingApplication.applicant.contact_by_phone_number_accepted,
      );
      setValue(
        'terms_and_conditions_accepted',
        !!onboardingApplication.applicant.terms_and_conditions_accepted,
      );
    }
  }, [onboardingApplication, setValue, navigate]);

  const onPostSuccess = (response: OnboardingApplication) => {
    setSubmitLoading(false);
    checkForRejections({
      application: response,
      onNoRejections: () => {
        onNextStep();
      },
    });
  };

  const driverDetailsMutation = useMutation({
    mutationFn: (params: {
      personalInfoInput: PersonalInfoInput;
      openingId: string;
      applicantId: string;
    }) => {
      return postPersonalInfo(
        params.personalInfoInput,
        params.openingId,
        params.applicantId,
      );
    },
    onSuccess: onPostSuccess,
  });

  const onSubmit: SubmitHandler<PersonalInfoInput> = formData => {
    const openingIdParam = openingId || onboardingApplication?.opening?.id;
    if (!openingIdParam) return;

    const applicantIdParam =
      applicantId || onboardingApplication?.applicant?.id;
    if (!applicantIdParam) return;
    setSubmitLoading(true);

    driverDetailsMutation.mutate({
      personalInfoInput: formData,
      openingId: openingIdParam,
      applicantId: applicantIdParam,
    });
  };

  return (
    <div>
      <form
        className="flex flex-col space-y-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* // First name, last name, phone number, email */}
        <div className="flex flex-col space-y-4">
          <InputForm
            label={t('FIRST_NAME_LABEL')}
            error={errors.first_name}
            required
            inputProps={{
              ...register('first_name', {
                required: {
                  value: true,
                  message: t('REQUIRED_FIELD', {
                    ns: 'common',
                    replace: { fieldName: t('FIRST_NAME_LABEL') },
                  }),
                },
              }),
            }}
          />
          <InputForm
            label={t('LAST_NAME_LABEL')}
            error={errors.last_name}
            required
            inputProps={{
              ...register('last_name', {
                required: {
                  value: true,
                  message: t('REQUIRED_FIELD', {
                    ns: 'common',
                    replace: { fieldName: t('LAST_NAME_LABEL') },
                  }),
                },
              }),
            }}
          />
          <InputForm
            label={t('EMAIL_LABEL')}
            error={errors.email}
            inputProps={{
              ...register('email', {
                required: {
                  value: true,
                  message: t('REQUIRED_EMAIL'),
                },
              }),
              disabled: true,
            }}
          />
          <PatternInputForm
            label={t('PHONE_NUMBER_LABEL')}
            error={errors.phone_number}
            control={control}
            controllerProps={{
              name: 'phone_number',
              rules: {
                pattern: {
                  value: /^[2-9]\d{2}-\d{3}-\d{4}$/,
                  message: t('REQUIRED_PHONE_NUMBER'),
                },
                required: {
                  value: true,
                  message: t('REQUIRED_PHONE_NUMBER'),
                },
              },
            }}
            format="###-###-####"
            required
          />
        </div>

        {/* // Options */}
        <RadioGroupInputForm
          label={t('LICENSED_TWO_YEARS_OR_MORE_LABEL')}
          error={errors.licensed_two_years_or_more}
          control={control}
          controllerProps={{
            name: 'licensed_two_years_or_more',
            rules: {
              validate: value =>
                value === true ||
                value === false ||
                t('REQUIRED_OPTION', { ns: 'common' }),
            },
          }}
          items={[
            { value: 'licensed_yes', text: t('YES', { ns: 'common' }) },
            { value: 'licensed_no', text: t('NO', { ns: 'common' }) },
          ]}
          radioGroupProps={{
            onValueChange: e => e === 'licensed_yes',
            required: true,
          }}
          valueConvertion={valueControl => {
            if (valueControl == null) return undefined; // either null or undefined
            return valueControl ? 'licensed_yes' : 'licensed_no';
          }}
        />
        <RadioGroupInputForm
          label={t('VEHICLE_CURRENTLY_INSURED_LABEL')}
          labelHelp={t('VEHICLE_CURRENTLY_INSURED_LABEL2')}
          error={errors.vehicle_currently_insured}
          control={control}
          controllerProps={{
            name: 'vehicle_currently_insured',
            rules: {
              validate: value =>
                value === true ||
                value === false ||
                t('REQUIRED_OPTION', { ns: 'common' }),
            },
          }}
          items={[
            { value: 'insured_yes', text: t('YES', { ns: 'common' }) },
            { value: 'insured_no', text: t('NO', { ns: 'common' }) },
          ]}
          radioGroupProps={{
            onValueChange: e => e === 'insured_yes',
            required: true,
          }}
          valueConvertion={valueControl => {
            if (valueControl == null) return undefined; // either null or undefined
            return valueControl ? 'insured_yes' : 'insured_no';
          }}
        />
        <RadioGroupInputForm
          label={t('AGE_RANGE_LABEL')}
          labelHelp={t('AGE_RANGE_LABEL2')}
          error={errors.age_range}
          control={control}
          controllerProps={{
            name: 'age_range',
            rules: {
              required: {
                value: true,
                message: t('REQUIRED_OPTION', { ns: 'common' }),
              },
            },
          }}
          items={[
            { value: AgeRange.UNDER_TWENYTHREE, text: 'Under 23' },
            { value: AgeRange.TWENYTHREE_SIXTYNINE, text: '23-69' },
            { value: AgeRange.SEVENTY_OR_ABOVE, text: '70 or above' },
          ]}
          radioGroupProps={{
            onValueChange: e => e as AgeRange,
            required: true,
          }}
        />

        {/* // Checkboxes - Agreements */}
        <div className="flex flex-col space-y-2">
          <div className="flex">
            <div className="mr-2">
              <Controller
                name="contact_by_phone_number_accepted"
                control={control}
                rules={{
                  required: { value: true, message: t('AGREEMENTS_REQUIRED') },
                }}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    id="contact_by_phone_number_accepted"
                    data-testid="contact_by_phone_number_accepted"
                    onCheckedChange={checked => {
                      setValue('contact_by_phone_number_accepted', !!checked);
                    }}
                  />
                )}
              />
            </div>
            <Label
              className="text-radio-button cursor-pointer text-sm"
              htmlFor="contact_by_phone_number_accepted"
            >
              {t('CONTACT_BY_PHONE_NUMBER_ACCEPTED_LABEL')}
            </Label>{' '}
          </div>

          <div className="flex">
            <div className="mr-2">
              <Controller
                name="terms_and_conditions_accepted"
                control={control}
                rules={{
                  required: { value: true, message: t('AGREEMENTS_REQUIRED') },
                }}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    id="terms_and_conditions_accepted"
                    data-testid="terms_and_conditions_accepted"
                    onCheckedChange={checked => {
                      setValue('terms_and_conditions_accepted', !!checked);
                    }}
                  />
                )}
              />
            </div>
            <div>
              <div>
                <Label
                  className="text-radio-button cursor-pointer text-sm"
                  htmlFor="terms_and_conditions_accepted"
                >
                  {t('TERMS_AND_CONDITIONS_ACCEPTED_LABEL')}
                </Label>
              </div>
              <div>
                <Button
                  data-testid="terms-of-use-button"
                  variant="link"
                  size="sm"
                  className="h-0.5"
                >
                  <a
                    data-testid="terms-of-use-link"
                    rel="noreferrer"
                    href={DISPATCH_PLATFORM_PROVIDER_AGREEMENT_URL}
                    className="text-action-blue visited:text-purple-800"
                    target="_blank"
                  >
                    {t('DOCUMENTS_DISPATCH_PLATFORM_PROVIDER_AGREEMENT')}
                  </a>
                </Button>
              </div>
              <div>
                <Button
                  data-testid="privacy-policy-button"
                  variant="link"
                  size="sm"
                  className="h-0.5"
                >
                  <a
                    data-testid="privacy-policy-link"
                    rel="noreferrer"
                    href={DISPATCH_PRIVACY_POLICY_URL}
                    className="text-action-blue visited:text-purple-800"
                    target="_blank"
                  >
                    {t('DOCUMENTS_DISPATCH_PRIVACY_POLICY')}
                  </a>
                </Button>
              </div>
              <div>
                <Button
                  data-testid="harassment-policy-button"
                  variant="link"
                  size="sm"
                  className="h-0.5"
                >
                  <a
                    data-testid="harassment-policy-link"
                    rel="noreferrer"
                    href={DISPATCH_HARASSMENT_POLICY_URL}
                    className="text-action-blue visited:text-purple-800"
                    target="_blank"
                  >
                    {t('DOCUMENTS_DISPATCH_HARASSMENT_POLICY')}
                  </a>
                </Button>
              </div>
            </div>
          </div>
          <div className="mt-2">
            {(errors.contact_by_phone_number_accepted ||
              errors.terms_and_conditions_accepted) && (
              <FormErrorField>
                {errors.contact_by_phone_number_accepted
                  ? errors.contact_by_phone_number_accepted.message
                  : errors.terms_and_conditions_accepted?.message}
              </FormErrorField>
            )}
          </div>
        </div>

        <ButtonSteps
          submitLoading={submitLoading}
          hasErrors={Object.keys(errors).length > 0}
        />
      </form>
    </div>
  );
}

export default PersonalInfoForm;
