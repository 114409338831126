import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import LoadingIFrame from '../../../components/iframe-loading/iframe-loading';
import Spinner from '../../../components/spinner';
import { useTranslation } from '../../../hooks/use-translation';
import { OutletContextProps } from '../application-steps.page';
import ButtonSteps from '../components/buttons-steps';
import getBackgroundCheckReportFormUrl from './driver-certification.service';

const onMessage = (event: { data: string }) => {
  let message;
  try {
    // need to parse event sent by iframe
    message = JSON.parse(event.data);
  } catch (e) {
    /* intentionally not caught */
  }

  if (!message) {
    return;
  }
  const messageName = get(message, 'name', '');
  switch (messageName) {
    case 'yardstik:form_submitted':
      // eslint-disable-next-line no-console
      console.log('Yardstik form submitted');
      window.location.href = '/status/sent';
      break;
    default:
      break;
  }
};

function DriverBackgroundCheck(): JSX.Element {
  const { onboardingApplication } = useOutletContext<OutletContextProps>();
  const { t } = useTranslation('driver-certifications');

  const applicantId = onboardingApplication?.applicant.id;

  const { isLoading, data: reportForm } = useQuery(
    ['report-form-url', { applicantId }],
    () =>
      getBackgroundCheckReportFormUrl({
        applicantId: applicantId || '',
      }),
    { retry: false },
  );

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  return (
    <div>
      <LoadingIFrame message={t('IFRAME_TAKES_FEW_SECONDS')} duration={10000} />

      <form className="flex flex-col space-y-8">
        {isLoading && (
          <div className="h-screen flex justify-center">
            <Spinner />
          </div>
        )}
        {!isLoading && reportForm && (
          <div className="bg-gray-100">
            <iframe
              title="iframe-yardstik"
              src={reportForm.url}
              allow="camera https://profile.yardstik-staging.com; camera https://profile.yardstik.com;"
              width="100%"
              height="1100px"
            />
          </div>
        )}

        <ButtonSteps hideNext submitLoading={false} />
      </form>
    </div>
  );
}

export default DriverBackgroundCheck;
