import { Input } from '@dispatch-ui/react';
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import FormErrorLabel from '../form-error-label';
import LabelForm from './label-form';
import { getInputErrorClassName } from './styles.util';

function PatternInputForm<T extends FieldValues>({
  control,
  controllerProps,
  format,
  error,
  label,
  labelHelp,
  mask = '',
  required = false,
}: {
  control: Control<T>;
  controllerProps: Omit<UseControllerProps<T>, 'data-testid'>;
  format: string;
  error?: FieldError;
  label?: string;
  labelHelp?: string;
  mask?: string;
  required?: boolean;
}) {
  const inputClassName = getInputErrorClassName(!!error);

  return (
    <div>
      <div className="mb-1">
        <LabelForm
          label={label}
          labelHelp={labelHelp}
          required={required}
          hasError={!!error}
        />
      </div>
      <Controller
        control={control}
        {...controllerProps}
        render={({ field: { onChange, value } }) => (
          <PatternFormat
            onChange={onChange}
            value={value}
            format={format}
            mask={mask}
            customInput={Input}
            className={inputClassName}
            data-testid={`${controllerProps.name}-input`}
          />
        )}
      />
      {error && <FormErrorLabel>{error.message}</FormErrorLabel>}
    </div>
  );
}

export default PatternInputForm;
