/* eslint-disable jsx-a11y/anchor-is-valid */
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import ButtonSpinner from '../../components/button-spinner';
import InputForm from '../../components/inputs-form/input-form';
import Typography from '../../components/typography';
import { useTranslation } from '../../hooks/use-translation';
import { initiateAuth } from './sign-in.service';

type SignInProps = {
  email: string;
  setEmail: (email: string) => void;
  onSubmit: () => void;
};

function SignIn({ email, setEmail, onSubmit }: SignInProps): JSX.Element {
  const { t } = useTranslation('sign-in');

  const [submitted, setSubmitted] = useState(false);
  const [errorEmail, setErrorEmail] = useState<FieldError>();
  const [loading, setLoading] = useState(false);

  const isValidatEmail = (emailUpdated: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(emailUpdated);
  };

  useEffect(() => {
    if (!email)
      setErrorEmail({ message: t('EMAIL_REQUIRED'), type: 'required' });
    else if (!isValidatEmail(email))
      setErrorEmail({ message: t('EMAIL_NOT_VALID'), type: 'pattern' });
    else setErrorEmail(undefined);
  }, [email, submitted, t]);

  const onClickContinue = async () => {
    setSubmitted(true);
    if (!errorEmail) {
      setLoading(true);
      initiateAuth({ email })
        .then(() => {
          onSubmit();
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('Error while sending email', error);
          enqueueSnackbar('Error while sending email', {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onClickContinue();
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className="flex text-center justify-center"
      onKeyDown={handleKeyDown}
    >
      <div className="space-y-4 w-80">
        <Typography size="sm" color="gray-600">
          {t('SIGN_IN_DESC')}
        </Typography>
        <div className="text-left">
          <InputForm
            omitAterisk
            label={t('EMAIL_LABEL')}
            error={submitted ? errorEmail : undefined}
            required
            inputProps={{
              name: 'email',
              onChange: e => setEmail(e.target.value),
              value: email,
              placeholder: 'email',
            }}
          />
        </div>

        <ButtonSpinner
          loading={loading}
          type="submit"
          data-testid="email-submit"
          className="w-full"
          onClick={onClickContinue}
        >
          {t('CONTINUE')}
        </ButtonSpinner>
      </div>
    </div>
  );
}

export default SignIn;
