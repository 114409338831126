import {
  API_BASE_URL,
  API_RELATIVE_PATH,
} from '../../constants/util.constants';
import { OnboardingApplication } from '../../types/applicant.types';

const getOnboardingApplication = async (
  applicantId: string,
): Promise<OnboardingApplication> => {
  const url = `${API_BASE_URL}${API_RELATIVE_PATH}/applicants/${applicantId}`;

  return fetch(url).then(res => {
    if (res.status === 500) {
      window.location.href = '/sign-in';
      throw new Error('Not logged in');
    }

    if (res.status === 401) {
      window.location.href = '/403';
      throw new Error('Unauthorized');
    }

    return res.json();
  });
};

export default getOnboardingApplication;
