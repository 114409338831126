import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Separator,
} from '@dispatch-ui/react';
import { LuUserCircle } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../hooks/use-translation';
import { BasicUserData } from '../../../types/personal-info.types';
import Typography from '../../typography';

type MultiLanguageOptionsProps = {
  currentLanguage: string;
  multiLanguageAvailable: boolean;
  changeLanguage: (language: 'en-US' | 'es') => void;
};

function MultiLanguageOptions({
  currentLanguage,
  multiLanguageAvailable,
  changeLanguage,
}: MultiLanguageOptionsProps): JSX.Element {
  if (!multiLanguageAvailable) {
    return <div />;
  }

  return (
    <>
      <Button
        data-testid="language-en-button"
        className="text-primary sm:text-white p-1"
        variant="link"
        onClick={() => {
          changeLanguage('en-US');
        }}
      >
        <Typography
          className={`${currentLanguage === 'en-US' ? 'underline' : 'no-underline'} text-primary sm:text-white text-xl sm:text-base`}
        >
          En
        </Typography>
      </Button>
      <span className="text-xl sm:text-base">/</span>
      <Button
        data-testid="language-es-button"
        className="text-primary sm:text-white p-1"
        variant="link"
        onClick={() => {
          changeLanguage('es');
        }}
      >
        <Typography
          className={`${currentLanguage !== 'en-US' ? 'underline' : 'no-underline'} text-primary sm:text-white text-xl sm:text-base`}
        >
          Es
        </Typography>
      </Button>
    </>
  );
}

type MenuSessionProps = MultiLanguageOptionsProps & {
  onLogin: () => void;
  onLogout: () => void;
};

type MenuProps = MultiLanguageOptionsProps & {
  currentUser: BasicUserData | undefined;
};

function MenuDesktop({
  onLogin,
  onLogout,
  currentUser,
  currentLanguage,
  multiLanguageAvailable,
  changeLanguage,
}: MenuProps & MenuSessionProps): JSX.Element {
  const { t } = useTranslation('sign-in');
  return (
    <div className="flex items-center justify-center text-white">
      {multiLanguageAvailable && (
        <MultiLanguageOptions
          currentLanguage={currentLanguage}
          multiLanguageAvailable={multiLanguageAvailable}
          changeLanguage={changeLanguage}
        />
      )}
      {!currentUser?.email && (
        <Button
          data-testid="login-button"
          variant="outline"
          className="ml-4 text-base text-white bg-primary"
          onClick={onLogin}
        >
          {t('SIGN_IN')}
        </Button>
      )}
      {currentUser?.email && (
        <div className="ml-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="secondary"
                size="icon"
                className="rounded-full ml-2"
                data-testid="user-menu"
              >
                <LuUserCircle className="h-5 w-5" />
                <span className="sr-only">Toggle user menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem>
                <div className="text-center">
                  {currentUser?.full_name && (
                    <div className="text-lg">{`${currentUser.full_name}`}</div>
                  )}
                  <div>{currentUser.email}</div>
                </div>
              </DropdownMenuItem>
              <Separator />
              <DropdownMenuItem
                data-testid="logout-dropdown-item"
                onClick={onLogout}
              >
                {t('LOGOUT')}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )}
    </div>
  );
}

function MenuMobile({
  onLogin,
  onLogout,
  currentUser,
  currentLanguage,
  multiLanguageAvailable,
  changeLanguage,
}: MenuProps & MenuSessionProps): JSX.Element {
  const { t } = useTranslation('sign-in');
  return (
    <div className="flex-col justify-center items-center text-center">
      {!currentUser?.email && (
        <div className="mb-2">
          <Button
            data-testid="login-mobile-button"
            variant="default"
            onClick={onLogin}
            className="w-full text-xl"
          >
            {t('SIGN_IN')}
          </Button>
        </div>
      )}
      {currentUser?.email && (
        <>
          <div className="text-center mb-2">
            {currentUser?.full_name && (
              <div className="text-lg">{`${currentUser.full_name}`}</div>
            )}
            <div>{currentUser.email}</div>
          </div>
          <Separator />
        </>
      )}
      <div className="my-2">
        <MultiLanguageOptions
          currentLanguage={currentLanguage}
          multiLanguageAvailable={multiLanguageAvailable}
          changeLanguage={changeLanguage}
        />
      </div>
      {currentUser?.email && (
        <Button
          data-testid="logout-mobile-button"
          variant="outline"
          className="text-xl w-full mt-2 text-primary"
          onClick={onLogout}
        >
          {t('LOGOUT')}
        </Button>
      )}
    </div>
  );
}

function Menu({
  currentUser,
  currentLanguage,
  multiLanguageAvailable,
  changeLanguage,
  isMobile,
}: MenuProps & { isMobile: boolean }): JSX.Element {
  const navigate = useNavigate();

  const onLogin = () => {
    navigate('/sign-in');
  };

  const onLogout = () => {
    window.location.href = '/auth/logout';
  };

  if (isMobile) {
    return (
      <MenuMobile
        data-testid="menu-mobile"
        onLogin={onLogin}
        onLogout={onLogout}
        currentUser={currentUser}
        currentLanguage={currentLanguage}
        multiLanguageAvailable={multiLanguageAvailable}
        changeLanguage={changeLanguage}
      />
    );
  }

  return (
    <MenuDesktop
      data-testid="menu-desktop"
      onLogin={onLogin}
      onLogout={onLogout}
      currentUser={currentUser}
      currentLanguage={currentLanguage}
      multiLanguageAvailable={multiLanguageAvailable}
      changeLanguage={changeLanguage}
    />
  );
}

export default Menu;
