const authenticateWithMagicAuth = async (input: {
  email: string;
  code: string;
}) => {
  const response = await fetch('/api/v1/magic_auth/authenticate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(input),
  });
  const json = await response.json();
  return json;
};

const initiateAuth = async (input: { email: string }) => {
  const response = await fetch('/api/v1/magic_auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(input),
  });
  const json = await response.json();
  return json;
};

export { authenticateWithMagicAuth, initiateAuth };
