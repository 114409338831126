import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Label,
  Separator,
} from '@dispatch-ui/react';
import { useEffect, useState } from 'react';
import { FiDownload, FiTrash2 } from 'react-icons/fi';
import { useTranslation } from '../../hooks/use-translation';
import { ExistingFile } from '../../types/document.types';
import formatBytes from '../../util/file-upload';
import FormErrorLabel from '../form-error-label';
import Typography from '../typography';

type UploadFileProps = {
  inputId: string;
  accept?: string[];
  maxSize?: number;
  files: FileList | null;
  existingFiles?: ExistingFile[];
  onRemoveExistingFile: (signed_id: string) => void;
  setFiles: (files: FileList | null) => void;
  multiple?: boolean;
  hasError?: boolean;
};

const MAX_NUMBER_OF_FILES = 10;

function UploadFile({
  inputId,
  accept = ['image/png', 'image/jpeg', 'application/pdf'],
  maxSize = 4e7,
  existingFiles,
  onRemoveExistingFile,
  files,
  setFiles,
  multiple = true,
  hasError = false,
}: UploadFileProps): JSX.Element {
  const { t } = useTranslation('common');

  const [errors, setErrors] = useState<string[]>([]);
  const [errorsAlertOpen, setErrorsAlertOpen] = useState<boolean>(false);

  const isValidFormat = (file: File) => {
    return accept.includes(file?.type);
  };

  const isValidSize = (file: File) => {
    return file.size <= maxSize;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filesToAdd = e.target.files;

    if (filesToAdd && filesToAdd.length > 0) {
      if (
        (files || []).length +
          (existingFiles || []).length +
          filesToAdd.length >
        (multiple ? MAX_NUMBER_OF_FILES : 1)
      ) {
        setErrors([
          t('ERROR_FILES_MAX_NUMBER_FILES', {
            replace: { max: (multiple ? MAX_NUMBER_OF_FILES : 1).toString() },
          }),
        ]);
        return;
      }

      // Use dataTransfer to manipulate file list
      const dtWithNewFiles = new DataTransfer();
      const dtWithNewFilesWithoutDups = new DataTransfer();

      // Only consider valid files
      const errorsTemp: string[] = [];
      Array.from(filesToAdd).forEach(file => {
        const validFormat = isValidFormat(file);
        const validSize = isValidSize(file);
        if (!validFormat)
          errorsTemp.push(
            t('ERROR_FILES_INVALID_FORMAT', {
              replace: { fileName: file.name },
            }),
          );
        else if (!validSize)
          errorsTemp.push(
            t('ERROR_FILES_EXCEED_SIZE', {
              replace: { fileName: file.name },
            }),
          );

        if (validFormat && validSize) dtWithNewFiles.items.add(file);
      });

      const dtWithInitialFiles = new DataTransfer();
      Array.from(files || []).forEach(file => {
        dtWithInitialFiles.items.add(file);
      });

      // Avoiding duplication
      Array.from(dtWithNewFiles.files || []).forEach((newFile: File) => {
        const fileAlreadyExist = Array.from(
          dtWithInitialFiles.files || [],
        ).some((existingFile: File) => {
          return existingFile.name === newFile.name;
        });

        if (!fileAlreadyExist) dtWithNewFilesWithoutDups.items.add(newFile);
      });

      Array.from(dtWithNewFilesWithoutDups.files || []).forEach(
        (file: File) => {
          dtWithInitialFiles.items.add(file);
        },
      );
      setFiles(dtWithInitialFiles.files);
      setErrors(errorsTemp);
    }
  };

  useEffect(() => {
    if (errors && errors.length > 0) {
      setErrorsAlertOpen(true);
    }
  }, [errors]);

  const onCloseErrorAlert = () => {
    setErrors([]);
    setErrorsAlertOpen(false);
  };

  const onRemoveFile = (fileName: string) => {
    if (!files) return;

    const dt = new DataTransfer();

    Array.from(files || []).forEach((file: File) => {
      if (fileName !== file.name) dt.items.add(file);
    });

    setFiles(dt.files);
  };

  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const customEvent = {
      target: {
        files: event.dataTransfer.files,
      },
    } as React.ChangeEvent<HTMLInputElement>;
    handleFileChange(customEvent);
  };

  return (
    <div>
      <AlertDialog open={errorsAlertOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Invalid files</AlertDialogTitle>
            <AlertDialogDescription>{t('ERROR_FILES')}:</AlertDialogDescription>
            <div>
              {errors.map(error => (
                <Typography size="sm" key={error}>
                  {error}
                </Typography>
              ))}
            </div>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={onCloseErrorAlert}
              data-testid="alert-dialog-close"
            >
              {t('CLOSE')}
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          id={inputId}
          data-testid={inputId}
          type="file"
          multiple={multiple}
          onChange={handleFileChange}
          className="hidden"
        />

        <Label
          className={`bg-secondary ${
            !hasError ? 'border-border' : 'border-red'
          } ${isDragging ? 'border-blue-500' : ''}
          flex h-[118px] w-full cursor-pointer flex-col items-center justify-center
          space-y-2 border-[1px] border-dashed p-3`}
          htmlFor={inputId}
        >
          <div>
            <FiDownload className="h-5 w-5" />
          </div>
          <div className="text-center">
            <Typography size="sm" color="black">
              {t('UPLOAD_PHOTO')}
            </Typography>
            <Typography size="sm" color="gray-600">
              {multiple && t('UPLOAD_PHOTO_RESTRICTIONS')}
              {!multiple && t('UPLOAD_PHOTO_RESTRICTIONS_ONE_FILE')}
            </Typography>
          </div>
        </Label>
      </div>
      <div className="m-2">
        {(existingFiles || [])
          // eslint-disable-next-line no-underscore-dangle
          .filter(file => file.signed_id && !file._destroy)
          .map(file => {
            return (
              <div key={file.signed_id}>
                <div className="my-1 flex flex-row">
                  <a
                    className="underline text-sm text-gray-600 grow"
                    href={file.url || ''}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {file.filename || ''}
                  </a>
                  <FiTrash2
                    className="cursor-pointer"
                    onClick={() => {
                      if (file.signed_id) onRemoveExistingFile(file.signed_id);
                    }}
                  />
                </div>
                <Separator />
              </div>
            );
          })}
        {files &&
          [...files].map(file => (
            <div key={file.name}>
              <div className="my-1 flex flex-row">
                <Typography size="sm" color="gray-600" className="grow">
                  {`${file.name} - ${formatBytes(file.size)}`}
                </Typography>
                <FiTrash2
                  className="cursor-pointer"
                  onClick={() => onRemoveFile(file.name)}
                />
              </div>
              <Separator />
            </div>
          ))}
      </div>
      {hasError && (
        <FormErrorLabel>
          {multiple
            ? t('REQUIRED_FILE_MULTIPLE', { ns: 'common' })
            : t('REQUIRED_FILE', { ns: 'common' })}
        </FormErrorLabel>
      )}
    </div>
  );
}

export default UploadFile;
