import { Button, Separator } from '@dispatch-ui/react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import EmptyBox from '../../../../assets/images/empty-box.svg?react';
import {
  validateAlreadyApplied,
  validateAlreadyDriver,
  validateBackgroundCheckFailed,
  validateIdentityVerificationBackgroundCheckFailed,
} from '../../components/multiple-applications-alert/util';
import PaginationComponent, {
  PaginationComponentProps,
} from '../../components/pagination/pagination.component';
import Typography from '../../components/typography/typography';
import { JOIN_OUR_NETWORK } from '../../constants/routes.constants';
import { useTranslation } from '../../hooks/use-translation';
import { CurrentUserContext } from '../../providers/current-user.context';
import { Openings } from '../../services/i18n/locale.types';
import { Opening } from '../../types/opening.types';
import { getVehicleTypeTranslationKey } from './utils';

function OpeningsList({
  availableOpenings,
  pageInfo,
}: {
  availableOpenings?: Opening[];
  pageInfo: PaginationComponentProps;
}): JSX.Element {
  const { t } = useTranslation('openings');

  const { existingApplications } = useContext(CurrentUserContext);

  const backgroundCheckFailed =
    validateBackgroundCheckFailed(existingApplications);

  const alreadyDriver = validateAlreadyDriver(existingApplications);
  const alreadyAppied = validateAlreadyApplied(existingApplications);
  const identityVerificationFailed =
    validateIdentityVerificationBackgroundCheckFailed(existingApplications);

  const cannotApply =
    backgroundCheckFailed ||
    alreadyDriver ||
    alreadyAppied ||
    identityVerificationFailed;

  const resuming = (openingId: string) =>
    existingApplications.some(
      application => application.opening?.id === openingId,
    );

  return (
    <div>
      {availableOpenings?.length === 0 && (
        <div className="mt-9">
          <div className="flex w-full justify-center text-gray-600">
            <Typography size="lg" color="gray-600">
              {t('NO_OPENINGS_MATCH_TITLE')}
            </Typography>
          </div>
          <div className="mt-3 flex w-full justify-center text-gray-600">
            <Typography
              size="sm"
              color="gray-600"
              className="max-w-[444px] text-center"
            >
              {t('NO_OPENINGS_MATCH_DESCRIPTION')}
            </Typography>
          </div>
          <div className="mt-6 flex justify-center">
            <EmptyBox />
          </div>
        </div>
      )}
      {(availableOpenings || []).length > 0 && (
        <div>
          <Typography size="xl" className="mt-4" color="primary">
            {t('AVAILABLE_OPENINGS_TITLE')}
          </Typography>
          <Separator className="mt-4" />
          {(availableOpenings || []).map(opening => (
            <div key={opening.id}>
              <div className="my-4 flex">
                <div className="grow">
                  <div className="flex-row sm:flex sm:items-center">
                    <Typography size="base" color="black">
                      {opening.location}
                    </Typography>
                    <Typography
                      size="xs"
                      color="black"
                      className="sm:ml-3 my-1.5 sm:mt-0.5 sm:mb-0 font-light"
                    >
                      {opening.position}
                    </Typography>
                  </div>
                  <Typography size="sm" color="black">
                    {t(
                      getVehicleTypeTranslationKey(
                        opening.vehicle_type,
                      ) as keyof Openings,
                    )}
                  </Typography>
                </div>
                <div>
                  {!cannotApply && (
                    <Link to={`/${JOIN_OUR_NETWORK}/${opening.id}`}>
                      <Button
                        data-testid="view-button"
                        variant="default"
                        size="sm"
                      >
                        {resuming(opening.id || '')
                          ? t('RESUME', { ns: 'common' })
                          : t('VIEW', { ns: 'common' })}
                      </Button>
                    </Link>
                  )}
                  {cannotApply && (
                    <Button
                      data-testid={`view-button-${opening.id}`}
                      variant="default"
                      size="sm"
                      disabled
                    >
                      {resuming(opening.id || '')
                        ? t('RESUME', { ns: 'common' })
                        : t('VIEW', { ns: 'common' })}
                    </Button>
                  )}
                </div>
              </div>
              <Separator />
            </div>
          ))}
        </div>
      )}
      {(availableOpenings || []).length > 0 && (
        <div className="mt-2">
          <PaginationComponent
            hasNextPage={pageInfo.hasNextPage}
            hasPreviousPage={pageInfo.hasPreviousPage}
            handleNextPage={pageInfo.handleNextPage}
            handlePreviousPage={pageInfo.handlePreviousPage}
            handleFirstPage={pageInfo.handleFirstPage}
          />
        </div>
      )}
    </div>
  );
}

export default OpeningsList;
