import { useEffect, useState } from 'react';

function LoadingIFrame({
  message,
  duration,
}: {
  message: string;
  duration: number;
}) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  return (
    <div
      className={`text-sm text-gray-600 text-center transition-opacity duration-1000  ${
        isVisible ? 'opacity-100 h-auto' : 'opacity-0 h-0'
      }`}
    >
      {message}
    </div>
  );
}

export default LoadingIFrame;
