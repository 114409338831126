import { FormEvent, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import FormErrorField from '../../../components/form-error-label';
import Typography from '../../../components/typography';
import { useTranslation } from '../../../hooks/use-translation';
import {
  DocumentToSignStatus,
  SigningDocumentEnum,
} from '../../../types/document.types';
import { OutletContextProps } from '../application-steps.page';
import ButtonSteps from '../components/buttons-steps';
import SigningDocument from './signing-document';
import { getDocument } from './util';

function ReviewAndSignForm(): JSX.Element {
  const { onNextStep, onboardingApplication } =
    useOutletContext<OutletContextProps>();

  const { t } = useTranslation('review-and-sign');
  const [nextClicked, setNextClicked] = useState<boolean>(false);

  const contractAgreement = getDocument(
    onboardingApplication,
    SigningDocumentEnum.CONTRACTOR_AGREEMENTS,
  );

  const contractAgreementSigned =
    contractAgreement?.status &&
    [DocumentToSignStatus.COMPLETED, DocumentToSignStatus.PROCESSING].includes(
      contractAgreement?.status,
    );

  const paic = getDocument(onboardingApplication, SigningDocumentEnum.PAIC);
  const paicSigned =
    paic?.status &&
    [DocumentToSignStatus.COMPLETED, DocumentToSignStatus.PROCESSING].includes(
      paic?.status,
    );

  const onNextStepClicked = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    if (contractAgreementSigned && paicSigned) {
      onNextStep();
    }
    setNextClicked(true);
  };

  const contractAgreementRequiredError =
    nextClicked && !contractAgreementSigned;

  const paicRequiredError = nextClicked && !paicSigned;

  return (
    <div>
      <form
        className="flex flex-col"
        onSubmit={ev => {
          onNextStepClicked(ev);
        }}
      >
        <Typography size="sm" color="black" className="mb-4">
          {t('PAGE_DESCRIPTION')}
        </Typography>
        {/* Contractor's Agreements */}
        {contractAgreement && (
          <div>
            <SigningDocument
              document={contractAgreement}
              documentTitle={t('CONTRACTORS_AGREEMENTS_PROVIDER_AGREEMENT')}
              documentDescription={t(
                'CONTRACTORS_AGREEMENTS_PROVIDER_AGREEMENT_DESC',
              )}
              hasErrors={contractAgreementRequiredError}
            />
            {contractAgreementRequiredError && (
              <FormErrorField>
                {t('REQUIRED_SIGNING', { ns: 'common' })}
              </FormErrorField>
            )}
          </div>
        )}
        {/* PAIC documents */}
        {paic && (
          <div>
            <SigningDocument
              document={paic}
              documentTitle={t('PAIC_DOCUMENTS')}
              documentDescription={t('PAIC_DOCUMENTS_DESC')}
              hasErrors={paicRequiredError}
            />
            {paicRequiredError && (
              <FormErrorField>
                {t('REQUIRED_SIGNING', { ns: 'common' })}
              </FormErrorField>
            )}
          </div>
        )}
        <ButtonSteps
          hasErrors={contractAgreementRequiredError || paicRequiredError}
        />
      </form>
    </div>
  );
}

export default ReviewAndSignForm;
