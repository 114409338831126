import { Button } from '@dispatch-ui/react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import ButtonSpinner from '../../../components/button-spinner';
import FormErrorField from '../../../components/form-error-label';
import { JOIN_OUR_NETWORK } from '../../../constants/routes.constants';
import { useTranslation } from '../../../hooks/use-translation';
import { OutletContextProps } from '../application-steps.page';

type ButtonStepsProps = {
  hideNext?: boolean;
  submitLoading?: boolean;
  hasErrors?: boolean;
};

function ButtonSteps({
  hideNext = false,
  submitLoading = false,
  hasErrors = false,
}: ButtonStepsProps): JSX.Element {
  const { t } = useTranslation('common');

  const { onPrevStep, isPrevEnable, isNextEnable, isLastStep } =
    useOutletContext<OutletContextProps>();

  const navigate = useNavigate();

  return (
    <div className="flex w-full">
      {isPrevEnable && (
        <div className="w-full grow">
          <Button
            data-testid="prev-step-button"
            variant="link"
            type="button"
            onClick={() => {
              if (isPrevEnable) onPrevStep();
              else navigate(JOIN_OUR_NETWORK);
            }}
          >
            {`< ${t('BACK')}`}
          </Button>
        </div>
      )}
      {!isPrevEnable && <div className="w-full grow" />}
      {isNextEnable && !hideNext && (
        <div className="flex flex-col w-[220px] items-end">
          {hasErrors && (
            <div className="mb-2">
              <FormErrorField>
                {t('REQUIRED_FIX', { ns: 'common' })}
              </FormErrorField>
            </div>
          )}
          <ButtonSpinner
            data-testid="next-submit"
            type="submit"
            size="lg"
            variant="default"
            loading={submitLoading}
          >
            {isLastStep ? t('FINISH') : t('NEXT')}
          </ButtonSpinner>
        </div>
      )}
    </div>
  );
}

export default ButtonSteps;
