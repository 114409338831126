import { useOutletContext } from 'react-router-dom';
import Typography from '../../../components/typography';
import { EMAIL_DRIVER_CARE } from '../../../constants/util.constants';
import { useTranslation } from '../../../hooks/use-translation';
import { OutletContextProps } from '../application-steps.page';
import ButtonSteps from '../components/buttons-steps';

function DriverCertification(): JSX.Element {
  const { onNextStep } = useOutletContext<OutletContextProps>();
  const { t } = useTranslation('driver-certifications');

  return (
    <div>
      <form
        className="flex flex-col space-y-8"
        onSubmit={() => {
          onNextStep();
        }}
      >
        <Typography size="sm" className="" color="black">
          {t('DESCRIPTION_A')}
        </Typography>
        <Typography size="sm" className="text-black" color="black">
          {t('DESCRIPTION_B')}
        </Typography>
        <Typography size="sm" className="text-black ml-4" color="black">
          <ul className="list-disc">
            <li> {t('DESCRIPTION_B_1')}</li>
            <li> {t('DESCRIPTION_B_2')}</li>
            <li> {t('DESCRIPTION_B_3')}</li>
            <li> {t('DESCRIPTION_B_4')}</li>
            <li> {t('DESCRIPTION_B_5')}</li>
            <li> {t('DESCRIPTION_B_6')}</li>
          </ul>
        </Typography>
        <p className="mb-4 text-sm">
          {`${t('DESCRIPTION_C')} `}
          <a className="underline" href={`mailto:${EMAIL_DRIVER_CARE}}`}>
            {EMAIL_DRIVER_CARE}
          </a>
        </p>
        <ButtonSteps submitLoading={false} />
      </form>
    </div>
  );
}

export default DriverCertification;
