import { Input } from '@dispatch-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import MainContentCard from '../../components/layout/main-layout/main-content-card';
import MultipleApplicationsAlert from '../../components/multiple-applications-alert/multiple-applications-alert';
import Spinner from '../../components/spinner';
import Typography from '../../components/typography/typography';
import useDebounce from '../../hooks/use-debounce';
import { useTranslation } from '../../hooks/use-translation';
import OpeningsList from './openings-list';
import fetchOpenings from './openings-service';

function AvailableOpeningsPage(): JSX.Element {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [textSearch, setTextSearch] = useState<string>('');
  const debouncedSearch = useDebounce(textSearch, 300);

  const { t } = useTranslation('openings');

  const { isLoading, data: availableOpeningsData } = useQuery(
    ['openings', { page: currentPage, debouncedSearch }],
    () => fetchOpenings(debouncedSearch, currentPage),
  );

  const availableOpenings = availableOpeningsData?.openings;
  const hasNextPage = availableOpeningsData?.page_info.has_next_page || false;
  const hasPreviousPage =
    availableOpeningsData?.page_info.has_previous_page || false;

  const handleTextSearchValue: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    setTextSearch(e.target.value);
  };

  return (
    <MainContentCard
      title={t('OPENINGS_PAGE_TITLE')}
      alert={<MultipleApplicationsAlert />}
      content={
        <div className="mt-2">
          <Typography size="sm" color="black">
            {t('SUBTITLE1')}
          </Typography>
          <Typography size="sm" color="black">
            {t('SUBTITLE2')}
          </Typography>
          <div className="mb-8 mt-4">
            <div className="mb-1">
              <Typography size="sm" fontWeight="semibold">
                {t('TEXT_SEARCH_LABEL')}
              </Typography>
            </div>
            <Input
              data-testid="text-search-input"
              placeholder={t('TEXT_SEARCH_PLACEHOLDER')}
              type="text"
              title={textSearch}
              onChange={handleTextSearchValue}
            />
          </div>
          {isLoading && (
            <div className="flex w-full justify-center">
              <Spinner />
            </div>
          )}
          {!isLoading && (
            <OpeningsList
              availableOpenings={availableOpenings}
              pageInfo={{
                hasNextPage,
                hasPreviousPage,
                handleNextPage: () => {
                  setCurrentPage(currentPage + 1);
                },
                handlePreviousPage: () => {
                  setCurrentPage(currentPage - 1);
                },
                handleFirstPage: () => {
                  setCurrentPage(1);
                },
              }}
            />
          )}
        </div>
      }
      footer={
        (availableOpenings || []).length > 0 ? (
          <div className="flex w-full justify-center text-gray-600">
            <Typography
              size="sm"
              color="gray-600"
              className="max-w-[444px] text-center"
            >
              {t('DONT_SEE_OPPORTUNITIES')}
            </Typography>
          </div>
        ) : (
          <div />
        )
      }
    />
  );
}

export default AvailableOpeningsPage;
