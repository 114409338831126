import { Input, InputProps } from '@dispatch-ui/react';
import { RefAttributes, useEffect, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import FormErrorLabel from '../form-error-label';
import { getInputErrorClassName } from './styles.util';

function PassCodeInputForm({
  error,
  inputProps,
  onInputChange,
  disabled = false,
}: {
  error?: FieldError;
  inputProps: Omit<InputProps, 'data-testid'> & RefAttributes<HTMLInputElement>;
  onInputChange: (value: string) => void;
  disabled?: boolean;
}) {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [inputs, setInputs] = useState(Array(6).fill(''));

  const inputClassName = getInputErrorClassName(!!error);

  useEffect(() => {
    const concatCode = inputs.join('');
    onInputChange(concatCode);
  }, [inputs, onInputChange]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleInputChange = (index: number, value: string) => {
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);

    if (value.length === 1 && index < inputs.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === 'Backspace' && inputs[index] === '' && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const paste = e.clipboardData.getData('text');
    if (paste.length === inputs.length && /^\d+$/.test(paste)) {
      const newInputs = paste.split('');
      setInputs(newInputs);
      inputRefs.current[inputs.length - 1].focus();
    }
  };

  return (
    <div>
      <div className="flex w-full justify-between">
        {inputs.map((input, index) => (
          <Input
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            ref={el => {
              inputRefs.current[index] = el!;
            }}
            value={input}
            onChange={e => handleInputChange(index, e.target.value)}
            onKeyDown={e => handleKeyDown(index, e)}
            onPaste={handlePaste}
            className={`${inputClassName} w-9`}
            data-testid={`${inputProps.name}-input-${index + 1}`}
            maxLength={1}
            disabled={disabled}
          />
        ))}
      </div>
      {error && <FormErrorLabel>{error.message}</FormErrorLabel>}
    </div>
  );
}

export default PassCodeInputForm;
