import { Route, Routes } from 'react-router-dom';
import {
  APPLICATION_STEP_DRIVER_BACKGROUND_CHECK,
  APPLICATION_STEP_DRIVER_CERTIFICATION,
  APPLICATION_STEP_DRIVER_DETAILS,
  APPLICATION_STEP_DRIVER_SKILLS,
  APPLICATION_STEP_PERSONAL_INFO,
  APPLICATION_STEP_REVIEW_SIGN,
  APPLICATION_STEP_VEHICLE_DETAILS,
} from '../../constants/routes.constants';
import { RedirectTo404 } from '../../services/sentry/error-boundary';
import ApplicationStepsPage from './application-steps.page';
import DriverBackgroundCheck from './driver-certification/driver-background-check';
import DriverCertification from './driver-certification/driver-certification';
import DriverDetailsForm from './driver-details/driver-details';
import DriverSkillsForm from './driver-skills/driver-skills';
import PersonalInfoForm from './personal-info/personal-info';
import ReviewAndSignForm from './review-and-sign/review-and-sign';
import VehicleDetailsForm from './vehicle-details/vehicle-details';

const getApplicatnStepPath = (step: string): string => {
  return `/${step}/applicant/:applicantId`;
};

function ApplicationStepsRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<ApplicationStepsPage />}>
        <Route
          path={getApplicatnStepPath(APPLICATION_STEP_PERSONAL_INFO)}
          element={<PersonalInfoForm />}
        />
        <Route
          path={getApplicatnStepPath(APPLICATION_STEP_DRIVER_SKILLS)}
          element={<DriverSkillsForm />}
        />
        <Route
          path={getApplicatnStepPath(APPLICATION_STEP_VEHICLE_DETAILS)}
          element={<VehicleDetailsForm />}
        />
        <Route
          path={getApplicatnStepPath(APPLICATION_STEP_DRIVER_DETAILS)}
          element={<DriverDetailsForm />}
        />
        <Route
          path={getApplicatnStepPath(APPLICATION_STEP_REVIEW_SIGN)}
          element={<ReviewAndSignForm />}
        />
        <Route
          path={getApplicatnStepPath(APPLICATION_STEP_DRIVER_CERTIFICATION)}
          element={<DriverCertification />}
        />
        <Route
          path={getApplicatnStepPath(APPLICATION_STEP_DRIVER_BACKGROUND_CHECK)}
          element={<DriverBackgroundCheck />}
        />

        <Route path={'/*'} element={<RedirectTo404 />} />
      </Route>
    </Routes>
  );
}

export default ApplicationStepsRoutes;
