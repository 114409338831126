import { Label, RadioGroup, RadioGroupItem } from '@dispatch-ui/react';
import { RefAttributes } from 'react';
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
  PathValue,
  UseControllerProps,
} from 'react-hook-form';
import FormErrorLabel from '../form-error-label';
import LabelForm from './label-form';
import { getInputErrorClassName } from './styles.util';

type RadioGroupProps = React.ComponentProps<typeof RadioGroup>;

export type RadioItemProps = {
  value: string;
  text: string;
};

function RadioGroupInputForm<T extends FieldValues>({
  control,
  controllerProps,
  error,
  items,
  label,
  labelHelp,
  radioGroupProps,
  valueConvertion,
}: {
  control: Control<T>;
  controllerProps: Omit<UseControllerProps<T>, 'data-testid'>;
  error?: FieldError;
  items: RadioItemProps[];
  label?: string;
  labelHelp?: string;
  radioGroupProps: Omit<RadioGroupProps, 'data-testid'> &
    RefAttributes<HTMLDivElement>;
  valueConvertion?: (value: PathValue<T, Path<T>>) => string | undefined;
}) {
  const inputClassName = getInputErrorClassName(!!error);

  return (
    <div>
      <div className="mb-2">
        <LabelForm
          label={label}
          labelHelp={labelHelp}
          required={radioGroupProps.required}
          hasError={!!error}
        />
      </div>

      <Controller
        control={control}
        {...controllerProps}
        render={({ field: { onChange, value: valueControl } }) => {
          let value;
          if (valueConvertion) value = valueConvertion(valueControl);
          else value = valueControl;

          return (
            <RadioGroup
              {...radioGroupProps}
              value={value}
              onValueChange={e => {
                if (radioGroupProps.onValueChange) {
                  const valueUpdated = radioGroupProps.onValueChange(e);
                  onChange(valueUpdated);
                }
              }}
              data-testid={`${controllerProps.name}-input`}
            >
              {items.map(item => (
                <div
                  key={item.value}
                  className={`flex items-center space-x-2 rounded-sm border-2 ${inputClassName}`}
                >
                  <RadioGroupItem
                    value={item.value}
                    id={item.value}
                    className="ml-3"
                    data-testid={`${controllerProps.name}-${item.value}-value`}
                  />
                  <Label
                    className="text-radio-button cursor-pointer w-full py-3 pr-3"
                    htmlFor={item.value}
                  >
                    {item.text}
                  </Label>
                </div>
              ))}
            </RadioGroup>
          );
        }}
      />
      {error && <FormErrorLabel>{error.message}</FormErrorLabel>}
    </div>
  );
}

export default RadioGroupInputForm;
