import {
  DOCUSIGN_CONTRACTOR_AGREEMENTS,
  DOCUSIGN_INTEGRATION_KEY,
  DOCUSIGN_PAIC,
} from '../../../constants/util.constants';
import { OnboardingApplication } from '../../../types/applicant.types';
import {
  DocumentToSign,
  SigningDocumentEnum,
} from '../../../types/document.types';

const loadDocuSign = async ({
  signingUrl,
  onDocumentSigned,
  onError,
}: {
  signingUrl: string;
  onDocumentSigned: (event: unknown) => void;
  onError: (ex: unknown, message: string) => void;
}) => {
  try {
    const docusign = await window.DocuSign.loadDocuSign(
      DOCUSIGN_INTEGRATION_KEY,
    );

    const signing = docusign.signing({
      url: `${signingUrl}&output=embed`,
      displayFormat: 'focused',
    });

    signing.on('sessionEnd', (event: unknown) => {
      onDocumentSigned(event);
    });

    signing.mount('#agreement');
  } catch (ex) {
    onError(ex, 'Error getting document');
  }
};

const getDocument = (
  onboardingApplication: OnboardingApplication | null,
  signingDocument: SigningDocumentEnum,
): DocumentToSign | undefined => {
  if (onboardingApplication?.applicant.documents)
    if (signingDocument === SigningDocumentEnum.CONTRACTOR_AGREEMENTS) {
      return onboardingApplication?.applicant.documents.find(
        document => document.name === DOCUSIGN_CONTRACTOR_AGREEMENTS,
      );
    } else if (signingDocument === SigningDocumentEnum.PAIC) {
      return onboardingApplication?.applicant.documents.find(
        document => document.name === DOCUSIGN_PAIC,
      );
    }

  return undefined;
};

export { getDocument, loadDocuSign };
