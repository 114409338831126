import { Button, Sheet, SheetContent, SheetTrigger } from '@dispatch-ui/react';
import { useContext, useEffect } from 'react';
import { LuMenu } from 'react-icons/lu';
import Logo from '../../../../../assets/images/logo-dispatch.svg?react';
import { CurrentUserContext } from '../../../providers/current-user.context';
import LanguageContext from '../../../providers/language.context';
import Menu from './menu';

function Header({
  multiLanguageAvailable = true,
}: {
  multiLanguageAvailable?: boolean;
}) {
  const { changeLanguage, currentLanguage } = useContext(LanguageContext);
  const { currentUser, fetchCurrentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    if (!currentUser) {
      fetchCurrentUser();
    }
  }, [currentUser, fetchCurrentUser]);

  return (
    <div>
      <div className="absolute w-full">
        <div className="mr-8 grid h-[81px] items-center justify-items-end text-white">
          <div className="flex items-center justify-center text-white">
            {/* Burger Menu Icon for Mobile View */}
            <div className="block sm:hidden">
              <Sheet>
                <SheetTrigger asChild>
                  <Button
                    variant="secondary"
                    size="icon"
                    className="rounded-full ml-2"
                    data-testid="user-menu"
                  >
                    <LuMenu className="h-5 w-5" />
                    <span className="sr-only">Toggle menu</span>
                  </Button>
                </SheetTrigger>
                <SheetContent side="top">
                  <Menu
                    isMobile
                    currentUser={currentUser}
                    currentLanguage={currentLanguage}
                    multiLanguageAvailable={multiLanguageAvailable}
                    changeLanguage={changeLanguage}
                  />
                </SheetContent>
              </Sheet>
            </div>
            {/* Menu Items Desktop */}
            <div className="hidden sm:flex">
              <Menu
                isMobile={false}
                currentUser={currentUser}
                currentLanguage={currentLanguage}
                multiLanguageAvailable={multiLanguageAvailable}
                changeLanguage={changeLanguage}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-primary flex h-[81px] items-center justify-center">
        <div>
          <Button
            data-testid="logo-button"
            variant="link"
            className="cursor-pointer z-50"
            onClick={() => {
              window.location.href = '/';
            }}
          >
            <Logo className="z-0" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Header;
