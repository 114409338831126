import {
  API_BASE_URL,
  API_RELATIVE_PATH,
} from '../../../constants/util.constants';
import { OnboardingApplication } from '../../../types/applicant.types';
import { DriverDetailsInput } from '../../../types/driver-details.input';

export type PostDriverDetailsParams = {
  driverDetails: DriverDetailsInput;
  signedIdFileProfilePhoto: string | null;
  signedIdFileLicenseFront: string | null;
  signedIdFileLicenseBack: string | null;
  applicantId: string;
};

async function postDriverDetails({
  driverDetails,
  signedIdFileProfilePhoto,
  signedIdFileLicenseFront,
  signedIdFileLicenseBack,
  applicantId,
}: PostDriverDetailsParams): Promise<OnboardingApplication> {
  const url = `${API_BASE_URL}${API_RELATIVE_PATH}/intake/driver-details/${applicantId}`;

  const options = {
    method: 'POST',
    body: JSON.stringify({
      driver_details: {
        profile_photo: signedIdFileProfilePhoto,
        address_attributes: {
          id: driverDetails.addressId,
          street_name: driverDetails.street,
          building_number: driverDetails.aptOrSuite,
          city: driverDetails.city,
          state: driverDetails.state,
          zip_code: driverDetails.zipCode,
        },
        tax_filling_status: driverDetails.taxFilingStatus,
        driver_license_expiration: driverDetails.driverLicenseExpiration,
        driver_license_front_image: signedIdFileLicenseFront,
        driver_license_back_image: signedIdFileLicenseBack,
      },
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  };

  return fetch(url, options).then(res => res.json());
}

export default postDriverDetails;
