import { Location } from 'react-router-dom';
import {
  APPLICATION_STEP_DRIVER_BACKGROUND_CHECK,
  APPLICATION_STEP_DRIVER_CERTIFICATION,
  APPLICATION_STEP_DRIVER_DETAILS,
  APPLICATION_STEP_DRIVER_SKILLS,
  APPLICATION_STEP_PERSONAL_INFO,
  APPLICATION_STEP_REVIEW_SIGN,
  APPLICATION_STEP_VEHICLE_DETAILS,
  ApplicationStepRoutes,
  STEPS,
  STEPS_SORTED,
} from '../../constants/routes.constants';
import { TranslateType } from '../../hooks/use-translation';
import { LocaleNamespaceType } from '../../services/i18n/locale.types';

function getPageTitle(
  t: TranslateType<LocaleNamespaceType>,
  activeStep?: ApplicationStepRoutes,
) {
  switch (activeStep) {
    case APPLICATION_STEP_PERSONAL_INFO:
      return t('PAGE_TITLE', { ns: 'personal-info' });
    case APPLICATION_STEP_DRIVER_SKILLS:
      return t('PAGE_TITLE', { ns: 'driver-skills' });
    case APPLICATION_STEP_VEHICLE_DETAILS:
      return t('PAGE_TITLE', { ns: 'vehicle-details' });
    case APPLICATION_STEP_DRIVER_DETAILS:
      return t('PAGE_TITLE', { ns: 'driver-details' });
    case APPLICATION_STEP_REVIEW_SIGN:
      return t('PAGE_TITLE', { ns: 'review-and-sign' });
    case APPLICATION_STEP_DRIVER_CERTIFICATION:
      return t('PAGE_TITLE', { ns: 'driver-certifications' });
    case APPLICATION_STEP_DRIVER_BACKGROUND_CHECK:
      return t('PAGE_TITLE', { ns: 'driver-certifications' });
    default:
      return '-';
  }
}

function getCurrentStep(location: Location): ApplicationStepRoutes {
  let currentStep = location.pathname.replace(`/${STEPS}/`, '');
  if (currentStep.indexOf('/') !== -1)
    currentStep = currentStep.substring(0, currentStep.indexOf('/'));

  return currentStep as ApplicationStepRoutes;
}

// If the user must edit some steps after submitted, we will show them only yhe steps they need to be edited
function getAllStepsOrReopenedSteps(
  reopenedSteps?: ApplicationStepRoutes[],
): ApplicationStepRoutes[] {
  if (!reopenedSteps || reopenedSteps.length === 0) return STEPS_SORTED;

  return reopenedSteps.map(
    // TODO remove ApplicationStepRoutes and use CurrentStepEnum instead
    // That will avoids code duplication and will be easier to maintain
    step => step.replaceAll('_', '-') as ApplicationStepRoutes,
  );
}

export { getAllStepsOrReopenedSteps, getCurrentStep, getPageTitle };
