import { buttonVariants } from '@dispatch-ui/react';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import MainContentCard from '../../components/layout/main-layout/main-content-card';
import Spinner from '../../components/spinner';
import Typography from '../../components/typography';
import { useTranslation } from '../../hooks/use-translation';
import { CurrentUserContext } from '../../providers/current-user.context';
import Passcode from './pass-code';
import SignIn from './sign-in';

function SignInPage(): JSX.Element {
  const { loadingCurrentUser, currentUser } = useContext(CurrentUserContext);
  const [email, setEmail] = useState('');
  const [onPassCodeForm, setOnPassCodeForm] = useState(false);
  const { t } = useTranslation('sign-in');

  if (loadingCurrentUser)
    return (
      <div className="flex items-center justify-center mt-5">
        <Spinner />
      </div>
    );

  if (currentUser?.email) {
    return (
      <MainContentCard
        title={t('SIGNED_IN')}
        content={
          <div className=" text-center justify-center space-y-4">
            <Typography size="sm">{`${t('SIGNED_IN_AS')} ${currentUser.email}`}</Typography>
            <Link className={buttonVariants({ variant: 'default' })} to="/">
              {t('GO_HOMEPAGE')}
            </Link>
          </div>
        }
      />
    );
  }

  return (
    <MainContentCard
      title={!onPassCodeForm ? t('SIGN_IN_TITLE') : t('CHECK_YOUR_EMAIL')}
      content={
        <div className="mt-2">
          {!onPassCodeForm && (
            <SignIn
              email={email}
              setEmail={setEmail}
              onSubmit={() => setOnPassCodeForm(true)}
            />
          )}
          {onPassCodeForm && <Passcode email={email} />}
        </div>
      }
    />
  );
}

export default SignInPage;
