import {
  API_BASE_URL,
  API_RELATIVE_PATH,
} from '../../../constants/util.constants';
import { OnboardingApplication } from '../../../types/applicant.types';
import { DriverSkillsInput } from '../../../types/driver-skills.input.types';

export type PostDriverSkillsMutationParams = {
  driverSkills: DriverSkillsInput;
  applicationId: string;
};

const postDriverSkills = async (
  params: PostDriverSkillsMutationParams,
): Promise<OnboardingApplication> => {
  const url = `${API_BASE_URL}${API_RELATIVE_PATH}/intake/applicant-vehicle-driver-skill/${params.applicationId}`;

  const options = {
    method: 'POST',
    body: JSON.stringify({
      applicant_vehicle_driver_skill: {
        vehicles_attributes: [
          {
            id: params.driverSkills.vehicleId,
            vehicle_type: params.driverSkills.vehicleType,
            features: params.driverSkills.features,
          },
        ],
        driver_skills_selected_options:
          params.driverSkills.skillsAndCertifications,
      },
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  };

  return fetch(url, options).then(res => res.json());
};

export default postDriverSkills;
