import { Button } from '@dispatch-ui/react';
import MainContentCard from '../../components/layout/main-layout/main-content-card';
import { useTranslation } from '../../hooks/use-translation';

function SignInAdmin(): JSX.Element {
  const { t } = useTranslation('sign-in');

  return (
    <MainContentCard
      title="Admin Sign In"
      content={
        <div className="text-center">
          <p className="text-sm">
            Sorry for the inconvinience, but dispatch emails must use a differnt
            sign-in method
          </p>
          <p className="text-sm">
            Plese use the following link to sign in/sign up:
          </p>

          <Button
            type="button"
            variant="default"
            data-testid="email-submit"
            className="w-full mt-4"
            onClick={() => {
              window.location.href = '/auth/';
            }}
          >
            {t('SIGN_IN')}
          </Button>
        </div>
      }
    />
  );
}

export default SignInAdmin;
