import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { ReactNode } from 'react';
import { CaptureException } from '../services/sentry/error-boundary';

const onError = (error: unknown) => {
  CaptureException(error as Error);
  enqueueSnackbar('Something went wrong', {
    variant: 'error',
    anchorOrigin: { horizontal: 'right', vertical: 'top' },
    action: snackbarId => (
      <button
        type="button"
        className="mr-5"
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        close
      </button>
    ),
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onSuccess = (data: any) => {
  if (data && data.errors && data.errors[0]) {
    CaptureException(data.errors[0]);
    // This is a workaround to stop the onSuccess from being called twice
    throw new Error('React query error');
  }
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onSuccess,
    onError,
  }),
  mutationCache: new MutationCache({
    onSuccess,
    onError,
  }),
});

function ReactQueryClient({ children }: { children: ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}

export default ReactQueryClient;
