import { Button } from '@dispatch-ui/react';
import { useParams } from 'react-router-dom';
import MainContentCard from '../../components/layout/main-layout/main-content-card';
import MultipleApplicationsAlert from '../../components/multiple-applications-alert/multiple-applications-alert';
import Typography from '../../components/typography/typography';
import { useTranslation } from '../../hooks/use-translation';

function JoinOurNetworkPage(): JSX.Element {
  const { openingId } = useParams();

  const { t } = useTranslation('join-our-network');

  const onStartApplication = () => {
    window.location.href = `/api/v1/check-user-session?opening_id=${openingId}`;
  };

  return (
    <MainContentCard
      title={t('PAGE_TITLE')}
      alert={<MultipleApplicationsAlert />}
      content={
        <div className="mt-2">
          <Typography size="xl" className="mb-4" color="primary">
            {t('ABOUT_THE_JOB_TITLE')}
          </Typography>
          <Typography size="sm" className="mb-4">
            {t('ABOUT_THE_JOB_DESCRIPTION')}
          </Typography>
          <Typography size="xl" className="mb-4" color="primary">
            {t('ABOUT_THE_APPLICATION_TITLE')}
          </Typography>
          <Typography size="sm" className="mb-4">
            {t('ABOUT_THE_APPLICATION_DESCRIPTION')}
          </Typography>
          <Typography size="sm" className="mb-4 ml-4">
            <ul className="list-disc">
              <li>{t('ABOUT_THE_APPLICATION_ITEM1')}</li>
              <li>{t('ABOUT_THE_APPLICATION_ITEM2')}</li>
              <li>{t('ABOUT_THE_APPLICATION_ITEM3')}</li>
            </ul>
          </Typography>
          <Typography size="sm" className="mb-4">
            {t('ABOUT_THE_APPLICATION_NOTE')}
          </Typography>
          <Typography size="xl" className="mb-4" color="primary">
            {t('BEFORE_GET_STARTED_TITLE')}
          </Typography>
          <Typography size="sm" className="mb-4">
            {t('BEFORE_GET_STARTED_DESCRIPTION')}
          </Typography>
          <Typography size="sm" className="mb-4 ml-4">
            <ul className="list-disc">
              <li>{t('BEFORE_GET_STARTED_ITEM1')}</li>
              <li>{t('BEFORE_GET_STARTED_ITEM2')}</li>
              <li>{t('BEFORE_GET_STARTED_ITEM3')}</li>
              <li>{t('BEFORE_GET_STARTED_ITEM4')}</li>
              <li>{t('BEFORE_GET_STARTED_ITEM5')}</li>
              <li>{t('BEFORE_GET_STARTED_ITEM6')}</li>
              <li>{t('BEFORE_GET_STARTED_ITEM7')}</li>
              <li>{t('BEFORE_GET_STARTED_ITEM8')}</li>
            </ul>
          </Typography>
        </div>
      }
      footer={
        <div className="w-full">
          <Button
            size="lg"
            variant="default"
            className="w-full"
            onClick={onStartApplication}
          >
            {t('START_APPLICATION_BUTTON')}
          </Button>
        </div>
      }
    />
  );
}

export default JoinOurNetworkPage;
