import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import MainApp from '../src/main.app';
import '../src/services/analytics/analytics';
import initI18nWithReact from '../src/services/i18n/i18n.service';
import '../src/services/sentry/sentry';

function renderApp(): void {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const rootElement = document.getElementById('root')!;
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <MainApp />
      </BrowserRouter>
    </React.StrictMode>,
  );
}

async function initApp(): Promise<void> {
  // eslint-disable-next-line no-console
  console.info('Initializing app');

  await initI18nWithReact();
  renderApp();
}

initApp().catch(err => {
  // eslint-disable-next-line no-console
  console.error('Error initializing app', { err });

  throw err;
});
