import {
  Calendar,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@dispatch-ui/react';
import { DateTime } from 'luxon';
import { useState } from 'react';
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';
import { FiCalendar } from 'react-icons/fi';
import FormErrorLabel from '../form-error-label';
import LabelForm from './label-form';
import { getInputErrorClassName } from './styles.util';

function CalendarInputForm<T extends FieldValues>({
  control,
  controllerProps,
  disableBeforeToday = true,
  error,
  label,
  labelHelp,
  required = false,
  setValue,
}: {
  control: Control<T>;
  controllerProps: Omit<UseControllerProps<T>, 'data-testid'>;
  disableBeforeToday?: boolean;
  error?: FieldError;
  label?: string;
  labelHelp?: string;
  required?: boolean;
  setValue: (newDate: Date | undefined) => void;
}) {
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const inputClassName = getInputErrorClassName(!!error);

  return (
    <div>
      <div className="mb-1">
        <LabelForm
          label={label}
          labelHelp={labelHelp}
          required={required}
          hasError={!!error}
        />
      </div>
      <Popover open={isPopoverOpen} onOpenChange={setPopoverOpen}>
        <PopoverTrigger asChild={false} className="w-full" role="cell">
          <div className="relative">
            <FiCalendar className="text-primary absolute left-2.5 top-2.5 h-4 w-4" />
            <Controller
              control={control}
              {...controllerProps}
              render={({ field }) => (
                <Input
                  name={controllerProps.name}
                  value={
                    field.value
                      ? DateTime.fromJSDate(field.value)
                          .toUTC()
                          .toFormat('MM/dd/yyyy')
                      : undefined
                  }
                  defaultValue={undefined}
                  placeholder="mm/dd/yyyy"
                  className={`pl-8 ${inputClassName}`}
                  data-testid={`${controllerProps.name}-input`}
                  onClick={() => setPopoverOpen(true)}
                />
              )}
            />
          </div>
        </PopoverTrigger>
        <PopoverContent className="w-80">
          <Controller
            control={control}
            {...controllerProps}
            render={({ field }) => (
              <Calendar
                disabled={
                  disableBeforeToday ? { before: new Date() } : undefined
                }
                captionLayout="dropdown"
                fromYear={2010}
                toYear={2050}
                styles={{
                  dropdown_month: {
                    marginLeft: '2em',
                    marginRight: '2em',
                  },
                  dropdown_year: {
                    marginLeft: '2em',
                    marginRight: '2em',
                  },
                }}
                mode="single"
                onSelect={newDate => {
                  if (newDate) {
                    setValue(newDate);
                    setPopoverOpen(false);
                  }
                }}
                selected={field.value}
                data-testid={`${controllerProps.name}-calendar`}
              />
            )}
          />
        </PopoverContent>
      </Popover>
      {error && <FormErrorLabel>{error.message}</FormErrorLabel>}
    </div>
  );
}

export default CalendarInputForm;
