import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@dispatch-ui/react';
import Typography from '../../typography';

type MainContentCardProps = {
  title: string;
  alert?: JSX.Element | string;
  content?: React.JSX.Element | string;
  footer?: JSX.Element | string;
};

function MainContentCard({
  title,
  alert,
  content = '',
  footer = '',
}: MainContentCardProps) {
  return (
    <div>
      {alert && <div className="mb-4 md:w-[580px] max-w-xl ">{alert}</div>}
      <Card className="mb-8 max-w-xl rounded-sm border-white bg-white shadow-md w-screen md:w-[580px]">
        <CardHeader>
          <CardTitle className="text-center">
            <Typography size="2xl" color="primary">
              {title}
            </Typography>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div>{content}</div>
        </CardContent>
        <CardFooter className="mt-2">{footer}</CardFooter>
      </Card>
    </div>
  );
}

export default MainContentCard;
